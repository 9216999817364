
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import style from "./style";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { ReactSelect } from "../../../components/Select/SelectSearch";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import MensagemWhatsappIcon from "../../../components/Icon/MensagemWhatsappIcon"
import { goToWhatsApp } from "../../../utils/goToWhatsApp";
import { FindMensagemSistemaByProfissionalSaudeId } from "../../../services/MensagensService";
import{campos} from "../../../utils/constants/camposMensagem"
import { getViziWebUrl } from "../../../config/config";


const ModalMensagemWhatsapp = observer(({
  open,
  close,
  classes,
  dadosMensagem,
  profissionalSaudeSelecionado,
  atendimentoStore,
  unidadeStore,
}) => {

  const pageableDTO ={
    pageNumber:0,
    pageSize:20,
    sortDir:"ASC",
    sortField:"mensagem",
  }

const[mensagens , setMensagens]=useState([]);
const[mensagemSelecionada , setMensagemSelecionada]=useState(null);
const[isLoading, setIsLoading]=useState(false);
const[mensagemPadrao, setMensagemPadrao]=useState(false);


useEffect(()=>{
  if (open) {
    carregarMensagens();
  }
},[open])
  
const carregarMensagens=async()=>{ 
    const mensagens = await FindMensagemSistemaByProfissionalSaudeId(profissionalSaudeSelecionado);
    setMensagens(mensagens);
    const mensagemPadraoSistema = process.env.REACT_APP_MENSAGEM_PADRAO
    const mensagemPadrao= mensagens.filter(item=> item.id === mensagemPadraoSistema)
    setMensagemSelecionada(...mensagemPadrao)
}

const clear=()=>{
  setMensagemSelecionada("")
}

const confirmarAcao= async()=>{
  setIsLoading(true);
  const{mensagem}=mensagemSelecionada;
  const{telefone, telefoneDDI,telefonePrincipalDDI,telefonePrincipal,chavePublica}=dadosMensagem;
  const configuracaoUnidade = unidadeStore.configuracaoUnidade
  const{horasPermiteConfirmarAgendamento}=configuracaoUnidade;

  await atendimentoStore.getConfiguracaoUnidadeLogada();
  let message ;
  let mensagemFormated = mensagem;
  
  const horasPermiteAgendamento= horasPermiteConfirmarAgendamento
  const isAgendamentoVizi=dadosMensagem?.paciente?.id
  const url = isAgendamentoVizi ? process.env.REACT_APP_CONFIRMAR_AGENDAMENTO_VIZI : process.env.REACT_APP_CONFIRMAR_AGENDAMENTO;
  const linkConfirmarCancelarAgendamento=`${url}${chavePublica}`

  if(dadosMensagem.remoto){
    const linkTelemedicina= `${getViziWebUrl()}/telemedicina/${chavePublica}`
    const dadosMensagensComLinkTelemedicina = {
      ...dadosMensagem,
      linkTelemedicina,
      linkConfirmarCancelarAgendamento,
      horasPermiteAgendamento
    }
    campos(dadosMensagensComLinkTelemedicina).forEach(element =>{
      mensagemFormated = mensagemFormated.replaceAll(element.campo,element.value);
       message = mensagemFormated.replace(/(?:\r\n|\r|\n)/g, '\n');
    })
  }else{
    const dadosDaMensagem = {
      ...dadosMensagem,
      linkConfirmarCancelarAgendamento,
      horasPermiteAgendamento,
    }
    campos(dadosDaMensagem).forEach(element =>{
      mensagemFormated = mensagemFormated.replaceAll(element.campo,element.value);
       message = mensagemFormated.replace(/(?:\r\n|\r|\n)/g, '\n');
    })
  }
  close();
  setIsLoading(false);
  clear()
  return goToWhatsApp(telefone ||
    telefonePrincipal,telefoneDDI || telefonePrincipalDDI, message);
}

const handleMensagem=(e)=>{
  setMensagemSelecionada(e);
}

  return (
    
    <div onClick={(e)=>e.stopPropagation()} className={classes.Wrapper}>
      <ModalConfirmacao
        open={open}
        onClose={close}
        icon={<MensagemWhatsappIcon/>}
        color={"#4390E1"}
        title={"Mensagens do sistema"}
        classes={{
          paperAlert: classes.paperAlert,
        }}
        buttons={{
          labelPrimaryButton: "Cancelar",
          handlePrimaryButton: close,
          disabledPrimaryButton: isLoading,
          labelSecondButton: "Continuar",
          handleSecondButton: () => confirmarAcao(),
          disabledSecondButton: !mensagemSelecionada,
          loadingSecondButton: isLoading,
        }}
      ><div >
        
          <div className={classes.content} onClick={(e)=>e.stopPropagation()}>
            <div className={classes.inputLabelContainer}  >
              <div className={classes.tip} >Selecione o tipo de mensagem</div >
              <ReactSelect
                className={classes.selectSearch}
                placeholder="Selecione"
                value={mensagemSelecionada}
                options={ mensagens || []}
                getOptionLabel={(option) => option.nome}
                getOptionValue={(option) => option.mensagem}
                onChange={(e)=> handleMensagem(e)}
              />
            </div>
          </div>
      </div>
      </ModalConfirmacao>
     </div>
  );
});


const ModalMensagemWhatsappWithStyles = withStyles(style)(ModalMensagemWhatsapp)
export default inject("atendimentoStore","filtroHeaderStore","unidadeStore")(ModalMensagemWhatsappWithStyles);




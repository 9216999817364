import React, { useEffect } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import Table from "../../../../../../../components/Table/Table";
import Scroll from "../../../../../../../components/InfiniteScroll/Scroll";
import InputMaskCurrency from "../../../../../../../components/Input/InputMaskCurrency";
import { TextFieldSearch } from "../../../../../../../components/TextField";
import { findAllProcedimentos } from "../../../../../../../services/OrcamentoService";
import TrashIcon from "../../../../../../../components/Icon/Trash";
import string from "../../../../../../../utils/string";
import { applyCurrencyMask } from "../../../../../../../utils/CurrencyMask";
import classNames from "classnames";

const inputStyle = {
  textAlign: "center",
  backgroundColor: "#F2F2F2",
  padding: "4px 8px",
  borderRadius: "8px",
  width: "100%",
  maxWidth: "100px",
  height: "32px",
};

const tableStatusStyle = {
  padding: "4px 16px",
};

const getColumns = ({
  handleChangeValorProcedimento,
  handleRemoveProcedimento,
}) => [
  {
    Header: "Procedimento",
    style: tableStatusStyle,
    getValue: (item) => item.procedimento.nome,
  },
  {
    Header: "Valor",
    style: tableStatusStyle,
    getValue: (item, index) => {
      return (
        <InputMaskCurrency
          value={item?.valorUnitario || ""}
          onChange={(e) => handleChangeValorProcedimento(e.target.value, index)}
          style={inputStyle}
        />
      );
    },
  },
  {
    Header: "",
    style: tableStatusStyle,
    getValue: (item, index) => (
      <TrashIcon
        size="20"
        onClick={() => handleRemoveProcedimento(item, index)}
      />
    ),
  },
];

const Procedimentos = observer(
  ({
    classes,
    handleFieldModal,
    orcamentoProcedimentos,
    orcamentoStore,
    orcamento,
  }) => {
    const { convenio, tipoPagamento, id } = orcamento || {};

    useEffect(() => {
      calculaProcedimentos();
    }, [orcamentoProcedimentos]);

    useEffect(() => {
      alteraValorProcedimentoPorConvenio();
    }, [convenio]);

    const alteraValorProcedimentoPorConvenio = () => {
      const valoresProcedimentoPorConvenio = orcamentoProcedimentos.map(
        (item) => {
          const valorUnitario = id ? item?.valorUnitario :
            item.procedimento?.convenioProcedimentos?.find(
              (convenioProcedimento) =>
                convenioProcedimento?.convenio?.id === convenio?.value
            )?.valor || 0;
          return { ...item, valorUnitario };
        }
      );

      orcamentoStore.orcamento.orcamentoProcedimentos = valoresProcedimentoPorConvenio;
    }

    const calculaProcedimentos = () => {
      const soma = orcamentoProcedimentos.reduce(
        (accumulator, currentValue) => {
          const valor =
            typeof currentValue?.valorUnitario === "string"
              ? string.currencyMaskToFloat(currentValue?.valorUnitario)
              : currentValue?.valorUnitario;
          return accumulator + valor;
        },
        0
      );
      orcamentoStore.orcamento.valor = soma;
    };

    const handleChangeValorProcedimento = (value, index) => {
      const selectedProcedimentos = [...orcamentoProcedimentos];
      selectedProcedimentos[index].valorUnitario = value;
      handleFieldModal("orcamentoProcedimentos", selectedProcedimentos);
    };

    const handleAddProcedimento = (procedimento) => {
      const valorUnitario =
        procedimento?.convenioProcedimentos?.find(
          (convenioProcedimento) =>
            convenioProcedimento?.convenio?.id === convenio?.value
        )?.valor || 0;

      const selectedProcedimentos = [
        ...orcamentoProcedimentos,
        {
          procedimento,
          valorUnitario,
        },
      ];
      handleFieldModal("orcamentoProcedimentos", selectedProcedimentos);
    };

    const loadProcedimentos = async (search, loadedOptions, { page }) => {
      return handleLoadMoreProcedimentos({
        search,
        data: { page },
      });
    };

    const handleRemoveProcedimento = (procedimento, index) => {
      const selectedProcedimentos = [...orcamentoProcedimentos];
      selectedProcedimentos.splice(index, 1);
      handleFieldModal("orcamentoProcedimentos", selectedProcedimentos);
    };

    const handleLoadMoreProcedimentos = async ({ search, data }) => {
      let pageableDTO = {
        sortDir: "ASC",
        sortField: "nome",
        pageNumber: data.page,
        pageSize: 20,
      };

      const response = await findAllProcedimentos({
        nome: search,
        ativo: true,
        pageableDTO,
        tiposProcedimentos: ["PROCEDIMENTO"],
      });

      const { content, last } = response;

      if (content) {
        return {
          options: content,
          hasMore: !last,
          additional: {
            page: data.page + 1,
          },
        };
      }
    };

    return (
      <div className={classes.container}>
        <div className={classes.contentProcedimentos}>
          <label className={classes.title}>Procedimentos</label>
          <TextFieldSearch
            classNotched={classes.notchedOutline}
            classInput={classes.inputTextField}
            classIcons={classes.classIcons}
            placeholder={"Selecione o procedimento"}
            onChange={(e, index) => handleAddProcedimento(e, index)}
            value={null}
            menuPlacement={"auto"}
            isClearable
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            withPaginate
            loadOptions={loadProcedimentos}
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </div>
        <Scroll classes={{ root: classes.tableRoot }}>
          <Table
            dados={orcamentoProcedimentos}
            columns={getColumns({
              handleChangeValorProcedimento,
              handleRemoveProcedimento,
            })}
            classes={{
              tabela: classNames(
                classes.table,
                tipoPagamento.value === "A_PRAZO" && classes.tableMenor
              ),
            }}
          />
        </Scroll>
        <div className={classes.contentTotal}>
          <label className={classes.total}> Total: </label>
          <label className={classes.totalValue}>
            {applyCurrencyMask(orcamento.valor)}
          </label>
        </div>
      </div>
    );
  }
);

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    height: "100%",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    borderRadius: "10px",

    "& thead tr": {
      textAlign: "left",
      height: "32px",
    },
  },
  tableRoot: {
    height: "100%",
    maxHeight: "132px",
  },
  contentProcedimentos: {
    margin: "16px 16px 0",
  },
  notchedOutline: {
    border: "0",
  },
  contentTotal: {
    height: "40px",
    paddingRight: "8px",
    textAlign: "end",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    gap: "4px",
  },
  total: {
    fontWeight: 600,
    fontSize: 16,
  },
  totalValue: {
    fontWeight: 600,
    fontSize: 18,
  },
  table: {
    height: "100%",
    overflow: "auto",
  },
  tableMenor: {
    maxHeight: "70px",
  },
};

const ProcedimentosWithStyles = withStyles(styles)(Procedimentos);
export default inject("orcamentoStore")(ProcedimentosWithStyles);

import React from "react";
import { inject, observer } from "mobx-react/index";
import { withStyles } from "@material-ui/core/styles/index";
import { Grid, Paper, List } from "@material-ui/core";

import Colors from "../../template/Colors";
import Scroll from "../../components/InfiniteScroll/Scroll";
import HeaderClose from "../../components/Header/HeaderClose";
import { Button } from "../../components/ui/Buttons";
import classNames from "classnames";

const styles = {
  gridFlexExpanded: {
    overflow: "auto",
    marginLeft: "0px",
    flex: 8,
    height: "calc(100% - 32px)",
  },
  gridFlexTecnico: {
    padding: '0 8px',
    background: Colors.primary.main,
    height: "100%",
  },
  paperListTitle: {
    flexBasis: "18%",
  },
  paperList: {
    marginBottom: 20,
    overflow: "auto",
    boxShadow: "none",
    height: "100%",
  },
  paperListTecnico: {
    padding: '8px',
  },
  listPadding: {
    padding: 2,
  },
  listItemMargin: {
    marginBottom: "10px",
  },
  listItemSelected: {
    border: "1px solid " + Colors.primary.main,
    borderRadius: "15px",
    padding: "10px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  paperListObservacoes: {
    height: "120px",
    overflow: "auto",
    boxShadow: "none",
    width: "95%",
    backgroundColor: Colors.commons.white,
    borderRadius: "10px",
    marginBottom: "15px",
    color: "#505050",
    "& h3, p": {
      marginLeft: "8px",
    },
  },

  listItem: {
    marginBottom: "16px",
    background: "#fff",
    borderRadius: "10px",
    padding: "17px 15px",
    boxShadow: "0px 1px 2px rgb(0 0 0 / 10%), 0px 2px 8px rgb(0 0 0 / 20%)",
  },
};

@inject("atendimentoStore")
@observer
class AtendimentoModalListaProcedimentos extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      podeRealizarProcedimento: false,
    };
  }

  componentDidMount() {
    const { tipoProfissionalLogado } = this.props.atendimentoStore;
    const podemRealizar = ["secretario", "tecnico", "medico", "administrador"];
    const podeRealizarProcedimento = podemRealizar.includes(
      tipoProfissionalLogado
    );
    this.setState({ podeRealizarProcedimento: podeRealizarProcedimento });
  }

  realizarProcedimento(procedimento) {
    const { atendimentoStore } = this.props;
    atendimentoStore.realizarProcedimento(procedimento.procedimento);
  }

  render() {
    const {
      classes,
      atendimentoStore,
      removeTituloModal,
      onClose,
    } = this.props;

    const { tipoProfissionalLogado } = atendimentoStore;
    const isTipoTecnico = tipoProfissionalLogado === "tecnico";
    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classNames(classes.gridFlexExpanded, isTipoTecnico && classes.gridFlexTecnico)}
      >
        {!removeTituloModal && (
          <HeaderClose title="Procedimentos:" onClose={onClose} />
        )}
        <Paper className={classNames(classes.paperList, isTipoTecnico && classes.paperListTecnico)}>
          <Scroll
            loadMore={this.loadMoreSujeitosAtencao}
            hasMore={!atendimentoStore.sujeitosAtencaoLastContent}
            pageStart={0}
            className={classes.scrollContainer}
          >
            <List className={classes.listPadding}>
              {atendimentoStore?.objView?.procedimentos
                ?.filter((item) => !item.realizado)
                .map((item, i) => (
                  <Grid
                    container
                    direction={"column"}
                    wrap={"nowrap"}
                    key={i}
                    className={classes.listItem}
                  >
                    <h3> {item.procedimento.nome} </h3>
                    <p> {item.procedimento.descricao} </p>
                    {this.state.podeRealizarProcedimento && (
                      <Button 
                        onClick={() => this.realizarProcedimento(item)} 
                        bgColor='#FBBF6D'
                        disabled={!atendimentoStore.objView?.id}
                      >
                        Realizar procedimento 
                      </Button>
                    )}
                  </Grid>
                ))}
            </List>
          </Scroll>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(AtendimentoModalListaProcedimentos);

import { action, observable } from 'mobx'
import { findAllNotificacao, marcarTodasNotificacoesLida } from '../services/NotificationsService'

export default class NotificationStore {
  @observable openModalNotifications = false
  @observable anchorEl = null
  @observable openFiltersNotifications = false
  @observable hasMore = true

  @observable notifications = []
  @observable paramsNotifications = {
    visualizado: false,
    tipoNotificacao: [],
    descricao: null,
    pageableDTO: {
      pageNumber: 0,
      pageSize: 10,
    },
  }

  @action findAllNotifications = async withFilters => {
    try {
      if (withFilters) {
        this.hasMore = true
      }
      this.paramsNotifications.pageableDTO.pageNumber = 0
      const variables = {
        ...this.paramsNotifications,
        ...(this.paramsNotifications.tipoNotificacao.length > 0 && {
          tipoNotificacao: this.paramsNotifications.tipoNotificacao.filter(
            item => item !== 'TODOS'
          ),
        }),
      }
      const response = await findAllNotificacao(variables)
      this.notifications = withFilters
        ? response.content
        : [
            ...this.notifications,
            ...response.content.filter(
              item => !this.notifications.some(notification => notification.id === item.id)
            ),
          ]
    } catch (error) {
      console.error(error)
    }
  }

  @action loadMoreNotifications = async () => {
    if (!this.hasMore) return
    try {
      this.paramsNotifications = {
        ...this.paramsNotifications,
        pageableDTO: {
          ...this.paramsNotifications.pageableDTO,
          pageNumber: this.paramsNotifications.pageableDTO.pageNumber + 1,
        },
      }
      const variables = { ...this.paramsNotifications }
      const response = await findAllNotificacao(variables)
      this.notifications = [...this.notifications, ...response.content]
      this.hasMore = !response.last
    } catch (error) {
      console.error(error)
    }
  }

  @action readAllNotifications = async () => {
    try {
      await marcarTodasNotificacoesLida()
      await this.findAllNotifications(true)
    } catch (error) {
      console.error(error)
    }
  }

  @action resetParamsNotifications = () => {
    this.paramsNotifications = {
      visualizado: false,
      tipoNotificacao: [],
      descricao: null,
      pageableDTO: {
        pageNumber: 0,
        pageSize: 10,
      },
    }
  }

  @action openModal = event => {
    this.anchorEl = event.currentTarget
    this.openModalNotifications = !this.openModalNotifications
  }

  @action closeModal = () => {
    this.anchorEl = null
    this.openModalNotifications = false
    this.openFiltersNotifications = false
  }

  @action toggleFiltersNotifications = () => {
    this.openFiltersNotifications = !this.openFiltersNotifications
  }
}

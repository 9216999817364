import { action, observable } from "mobx";
import Api, { NestorApi } from "../config/api";
import { createMutation } from "../pages/Querys";
import string from "../utils/string";
import { findByUnidadeLogadaConfiguracaoUnidade } from "../services/ConfiguracoesUnidadeService";
import { findAllEspanhaComunidadeAutonoma } from "../services/UnidadeService";
import localStorageService, { UNIDADE_LOGADA_KEY } from "../services/storage";

const unidadeDefault = {
    id: '',
    nome: '',
    nomeFantasia: '',
    codigoCnes: '',
    telefonePrincipal: '',
    telefonePrincipalDDI: '',
    perfilPublico: false,
    utilizaTelefoneInternacional: false,
    endereco: {
        cep: '',
        nomeLogradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        estado: {
            id: '',
            nome: ''
        },
        municipio: {
            id: '',
            nome: ''
        }
    },
    espanhaMunicipio: {
        id: '',
        descricao: ''
    }
};

const unidadePublicaDefault = {
    sobre: '',
    fotoPerfil: '',
    telefoneSecundario: '',
    email: ''
};

export default class unidadeStore {

    @observable unidade = unidadeDefault;

    @observable unidadePublica = unidadePublicaDefault;

    @observable estados = [];

    @observable municipios = [];

    @observable objView = {
        estado: null,
        municipio: null
    };

    @observable notification = {
        isOpen: false,
        variant: '',
        message: ''
    };

    @observable errors = {
        nome: false,
        nomeFantasia: false,
        codigoCnes: false,
        telefonePrincipal: false,
        endereco: false,
        cep: false,
        bairro: false,
        nomeLogradouro: false,
        numero: false,
        estado: false,
        municipio: false,
        espanhaMunicipio: false,
    };

    @observable errorsInfosPublicas = {
        telefoneSecundario: false,
        sobre: false,
        email: false
    };

    @observable loadingSaveDadosUnidade = false;

    @observable azulControleAtivado = false;

    @observable comunidadeAutonoma = null;

    @observable provincia = null;

    @observable espanhaComunidadesAutonoma = [];

    @observable espanhaProvincias = [];

    @observable espanhaMunicipios = [];
    @observable isLoadingInfo = false;
    @observable configuracaoUnidade = null;

    @action
    async getUnidadeLogada() {
        await Api.post('', {
            query: `{
                        findUnidadeLogada{
                            id
                            nome
                            nomeFantasia
                            codigoCnes
                            telefonePrincipal
                            telefonePrincipalDDI
                            perfilPublico
                            plugzapiClientToken
						    plugzapiUrl
                            endereco {
                                cep
                                nomeLogradouro
                                numero
                                complemento
                                bairro
                                estado {
                                    id
                                    nome
                                }
                                municipio {
                                    id
                                    nome
                                }
                            }
                            espanhaMunicipio{
                                descricao
                                id
                                provincia{
                                    descricao
                                    id
                                    comunidadeAutonoma{
                                        descricao
                                        id
                                    }
                                }
                            }
                        }
                        
                        findAllEstadoList(searchDTO: {sortDir: "ASC", sortField: "id"}) {
                          id
                          uf
                          nome
                        }
                    }
                `
        }).then((result) => {
            const { findUnidadeLogada, findAllEstadoList } = result.data.data;
            if (findUnidadeLogada) {
                this.unidade = {
                    ...findUnidadeLogada,
                    telefonePrincipal: findUnidadeLogada.telefonePrincipal && `${findUnidadeLogada.telefonePrincipalDDI} ${findUnidadeLogada.telefonePrincipal}`,
                    espanhaMunicipio: findUnidadeLogada.espanhaMunicipio && {
                        ...findUnidadeLogada.espanhaMunicipio,
                        value: findUnidadeLogada.espanhaMunicipio?.id,
                        label: string.capitalize(findUnidadeLogada?.espanhaMunicipio?.descricao)
                    }
                };

                if (findUnidadeLogada.espanhaMunicipio) {
                    this.provincia = findUnidadeLogada.espanhaMunicipio?.provincia && {
                        value: findUnidadeLogada.espanhaMunicipio?.provincia?.id,
                        label: string.capitalize(findUnidadeLogada.espanhaMunicipio?.provincia?.descricao)
                    };
                    this.comunidadeAutonoma = findUnidadeLogada.espanhaMunicipio?.provincia?.comunidadeAutonoma && {
                        value: findUnidadeLogada.espanhaMunicipio?.provincia?.comunidadeAutonoma?.id,
                        label: string.capitalize(findUnidadeLogada.espanhaMunicipio?.provincia?.comunidadeAutonoma?.descricao)
                    };
                }
                this.estados = findAllEstadoList.map(e => ({
                    value: e.id,
                    name: string.capitalize(e.nome)
                }));
                this.initObjView();
            } else {
                this.openNotification('Erro ao carregar os dados da unidade.', 'error');
            }

        });
    }

    initObjView() {
        this.objView.estado = this.unidade.endereco && this.unidade.endereco.estado ? {
            value: this.unidade.endereco.estado.id,
            label: this.unidade.endereco.estado.nome
        } : null;
        this.objView.municipio = this.unidade.endereco && this.unidade.endereco.municipio ? {
            value: this.unidade.endereco.municipio.id,
            label: this.unidade.endereco.municipio.nome
        } : null;

        if (this.objView.estado) {
            this.getMunicipioByEstado(this.objView.estado.value);
        }
    }

    @action
    async updateUnidadeLogada(location) {
        const unidade = await localStorageService.get(UNIDADE_LOGADA_KEY);
        const paisAtuacao = unidade?.rede?.paisAtuacao?.descricao;

        if ((location === 'dadosCadastrais' && !this.contemErros(paisAtuacao)) || (location === 'perfilVizi' && !this.validaDadosCadastraisPerfilVizi())) {
            try {
                this.loadingSaveDadosUnidade = true;
                await Api.post('',
                    JSON.stringify({
                        query: createMutation({
                            name: 'updateUnidadeLogada',
                            objType: 'UnidadeInput',
                            objName: 'unidade',
                            attrsReturn: `
                            id
                            nome
                            nomeFantasia
                            codigoCnes
                            telefonePrincipal
                            telefonePrincipalDDI
                            endereco {
                              cep
                              nomeLogradouro
                              numero
                              complemento
                              bairro
                              estado {
                                id
                                nome
                                uf
                              }
                              municipio {
                                id
                                nome
                              }
                            }
                            espanhaMunicipio {
                                label:descricao
                                value:id
                                provincia{
                                    descricao
                                    id
                                    comunidadeAutonoma{
                                        descricao
                                        id
                                    }
                                }
                            }
                            `
                        }, 'mutation'),
                        variables: {
                            unidade: {
                                ...this.unidade,
                                cnpj: this.unidade.cnpj || null,
                                telefonePrincipal: string.removeSpecialChars(this.unidade.telefonePrincipal).substr(this.unidade.telefonePrincipalDDI.length),
                                endereco: {
                                    ...this.unidade.endereco,
                                    cep: string.removeSpecialChars(this.unidade?.endereco?.cep),
                                    estado: this.objView.estado?.value ? {
                                        id: this.objView.estado?.value
                                    } : null,
                                    municipio: this.objView.municipio?.value ? {
                                        id: this.objView.municipio?.value
                                    } : null
                                },
                                espanhaMunicipio: this.unidade.espanhaMunicipio ? {
                                    id: this.unidade?.espanhaMunicipio?.value
                                } : null
                            }
                        }
                    })
                ).then((result) => {
                    const { updateUnidadeLogada } = result.data.data;
                    this.loadingSaveDadosUnidade = false;
                    if (!updateUnidadeLogada) {
                        throw ('Erro ao atualizar cadastro.');
                    }
                });
            } catch (error) {
                throw error;
            }
        } else {
            throw ('Campos obrigatórios não foram preenchidos!');
        }
    }

    @action
    async updateUnidadeLogadaInformacoesPublicas() {
        if (!this.contemErrosInfosPublicas()) {
            try {
                await Api.post('',
                    JSON.stringify({
                        query: createMutation({
                            name: 'updateUnidadeLogadaInformacoesPublicas',
                            objType: 'UnidadeInformacaoInput',
                            objName: 'unidadeInformacao',
                            attrsReturn: `
                            id
                            sobre
                            fotoPerfil
                            telefoneSecundario
                            email
                            `
                        }, 'mutation'),
                        variables: {
                            unidadeInformacao: this.unidadePublica
                        }
                    })
                ).then((result) => {
                    const { updateUnidadeLogadaInformacoesPublicas } = result.data.data;
                    if (updateUnidadeLogadaInformacoesPublicas) {
                        return updateUnidadeLogadaInformacoesPublicas;
                    } else {
                        throw 'Erro ao atualizar cadastro de informações públicas.';
                    }
                });
            } catch (error) {
                throw error;
            }
        } else {
            throw 'Campos obrigatórios das informações públicas não foram preenchidos!';
        }
    }

    @action
    async getUnidadePublicaLogada() {
        Api.post('', {
            query: `{
                findUnidadeLogadaInformacoesPublicas{
                        fotoPerfil
                        id
                        sobre
                        telefoneSecundario
                        email
                }
            }
                `
        }).then((result) => {
            const { findUnidadeLogadaInformacoesPublicas } = result.data.data;
            if (findUnidadeLogadaInformacoesPublicas) {
                this.unidadePublica.fotoPerfil = findUnidadeLogadaInformacoesPublicas?.fotoPerfil || "";
                this.unidadePublica.sobre = findUnidadeLogadaInformacoesPublicas?.sobre || "";
                this.unidadePublica.telefoneSecundario = findUnidadeLogadaInformacoesPublicas?.telefoneSecundario || "";
                this.unidadePublica.email = findUnidadeLogadaInformacoesPublicas?.email || "";
            } else {
                this.openNotification('Erro ao carregar os dados da unidade.', 'error');
            }
        });
    }

    @action
    async getEstadosList() {
        Api.post('', {
            query: `
            {
              findAllEstadoList {
                id
                uf
                nome
              }
            }
        `
        }).then((result) => {
            const { findAllEstadoList } = result.data.data;
            if (findAllEstadoList) {
                this.estados = findAllEstadoList.map(e => ({
                    value: e.id,
                    name: string.capitalize(e.nome)
                }));
            } else {
                this.openNotification('Erro ao carregar os estados.', 'error');
            }
        });
    }

    @action
    async getMunicipioByEstado(idEstado) {
        Api.post('', {
            query: `
                {
                  findByEstado(estado: {id: ${idEstado}}){
                    id
                    nome
                  }
                }           
            `
        }).then((result) => {
            const { findByEstado } = result.data.data;
            if (findByEstado) {
                this.municipios = findByEstado.map(m => ({
                    value: m.id,
                    name: string.capitalize(m.nome)
                }));
            } else {
                this.openNotification('Erro ao carregar os municipios.', 'error');
            }
        });
    }

    @action async validarCodigoTv(codigo, clientId, conexaoTV) {
        try {
            const response = await Api.post('', {
                query: `
                    mutation ($codigo: String, $clientId:OAuthClientId, $conexaoTV: ConexaoTV) {
                        validarCodigoTv(codigo: $codigo, clientId: $clientId, conexaoTV: $conexaoTV)
                    }
                `,
                variables: {
                    codigo,
                    clientId,
                    conexaoTV,
                }
            });

            if (response.data.errors) {
                this.openNotification('Código não encontrado.', 'error');
                return
            }

            return response.data.data.validarCodigoTv;
        } catch (error) {
            throw error;
        }
    }

    @action
    async updateImagemPerfil(fotoPerfilId) {
        if (!fotoPerfilId) return false;
        this.unidadePublica.fotoPerfil = fotoPerfilId
        return true;
    }

    @action async salvarImagem(file) {
        this.openNotification("Salvando imagem...", "info");
        const result = await this.saveFile(file);
        const data = await result.data;
        let retorno = false;
        await this.syncImageChanged(data);
        return retorno;
    }

    async saveFile(file) {
        if (file) {
            try {
                const formData = new FormData();
                formData.append('file', file);
                return NestorApi.post('/resource/AppHealth', formData);
            } catch (error) {
                throw error;
            }
        }
    }

    async syncImageChanged(fotoPerfil) {
        if (fotoPerfil && fotoPerfil.id) {
            try {
                Api.post('', JSON.stringify({
                    query: createMutation({
                        name: 'updateUnidadeLogadaFotoPerfil',
                        objType: 'UUID',
                        objName: 'fotoPerfilId',
                        attrsReturn:
                            `
                        id
                        fotoPerfil
                        sobre
                        `,
                    }, 'mutation'),
                    variables: {
                        fotoPerfilId: fotoPerfil.id
                    }
                })).then((res) => {
                    this.openNotification('Imagem alterada com sucesso.', 'success');
                    this.updateImagemPerfil(fotoPerfil.id)
                    return res
                })
            } catch (error) {
                this.openNotification('Erro ao salvar a nova imagem. Tente novamente!', 'error')
            }
        }
    }

    @action checkUsoTelefoneInternacional = async () => {
        try {
            if (!this.unidade.id) {
                await this.getUnidadeLogada();
            }
            const response = await findByUnidadeLogadaConfiguracaoUnidade();
            const unidadeConfiguracoes = response.data.data.findByUnidadeLogadaConfiguracaoUnidade;

            this.unidade.utilizaTelefoneInternacional = unidadeConfiguracoes?.utilizaTelefoneInternacional || false;
        } catch (error) {
            console.error(error.message);
        }
    }

    @action inicializaConfiguracaoUnidade = async () => {
        try {
            if (!this.unidade.id) {
                await this.getUnidadeLogada();
            }
            const response = await findByUnidadeLogadaConfiguracaoUnidade();
            const unidadeConfiguracoes = response.data.data.findByUnidadeLogadaConfiguracaoUnidade;

            this.configuracaoUnidade = unidadeConfiguracoes;
            this.azulControleAtivado = unidadeConfiguracoes?.azulControleAtivado || false;

        } catch (error) {
            console.error(error.message);
        }
    }

    contemErros = (paisAtuacao) => {
        this.errors = {
            nome: string.isEmpty(this.unidade.nome),
            nomeFantasia: string.isEmpty(this.unidade.nomeFantasia),
            codigoCnes: string.isEmpty(this.unidade.codigoCnes),
            telefonePrincipal: string.isEmpty(this.unidade.telefonePrincipal),
            cep: string.isEmpty(this.unidade.endereco.cep),
            bairro: string.isEmpty(this.unidade.endereco.bairro),
            nomeLogradouro: string.isEmpty(this.unidade.endereco.nomeLogradouro),
            numero: string.isEmpty(this.unidade.endereco.numero),
            estado: paisAtuacao === "BRASIL" && (this.objView.estado === undefined || this.objView.estado === null),
            municipio: paisAtuacao === "BRASIL" && (this.objView.municipio === undefined || this.objView.estado === null),
            espanhaMunicipio: paisAtuacao === "ESPANHA" && (this.unidade.espanhaMunicipio === undefined || this.unidade.espanhaMunicipio === null),
        };

        return this.errors.nome || this.errors.nomeFantasia || this.errors.codigoCnes || this.errors.telefonePrincipal
            || this.errors.cep || this.errors.bairro || this.errors.nomeLogradouro ||
            this.errors.numero || this.errors.estado || this.errors.municipio || this.errors.espanhaMunicipio;
    };

    validaDadosCadastraisPerfilVizi = () => {
        this.errors = {
            nome: string.isEmpty(this.unidade.nome),
            nomeFantasia: string.isEmpty(this.unidade.nomeFantasia),
            codigoCnes: string.isEmpty(this.unidade.codigoCnes),
            telefonePrincipal: string.isEmpty(this.unidade.telefonePrincipal),
        }
        return this.errors.nome || this.errors.nomeFantasia || this.errors.codigoCnes || this.errors.telefonePrincipal;
    }

    contemErrosInfosPublicas() {
        this.errorsInfosPublicas = {
            telefoneSecundario: string.isEmpty(this.unidadePublica.telefoneSecundario),
            sobre: string.isEmpty(this.unidadePublica.sobre) || string.isMaxLength(this.unidadePublica.sobre, 500),
            email: string.validaEmail(this.unidadePublica.email)
        }

        return this.errorsInfosPublicas.telefoneSecundario || this.errorsInfosPublicas.sobre
    }

    closeNotification = () => {
        this.notification.message = '';
        this.notification.variant = '';
        this.notification.isOpen = false;
    };

    resetNotification = () => {
        this.notification.message = '';
        this.notification.variant = '';
        this.notification.isOpen = false;
    };

    openNotification = (message, variant) => {
        this.notification.message = message;
        this.notification.variant = variant;
        this.notification.isOpen = true;
    };

    @action loadEspanhaComunidadesAutonoma = async () => {
        const response = await findAllEspanhaComunidadeAutonoma();

        this.espanhaComunidadesAutonoma = response.map(comunidadeAutonoma => ({
            value: comunidadeAutonoma.id,
            name: string.capitalize(comunidadeAutonoma.descricao)
        }));
    };

    @action findProvinciaBy(comunidadeAutonoma) {
        if (comunidadeAutonoma) {
            this.findProvincia(comunidadeAutonoma.value).then((result) => {
                this.espanhaProvincias = result ? result.map(item => ({
                    value: item.id,
                    name: string.capitalize(item.descricao)
                })) : this.espanhaProvincias;
            });
        } else {
            this.espanhaProvincias = []
        }
    };

    findProvincia = async (codigoComunidadeAutonoma) => {
        const response = await Api.post('',
            {
                query: `
                    query ($codigoComunidadeAutonoma: String, $pageableDTO: PageableDTOInput){
                        findAllEspanhaProvincia(codigoComunidadeAutonoma: $codigoComunidadeAutonoma, pageableDTO: $pageableDTO){
                            id
                            descricao                 
                        }
                    }`,
                variables: {
                    pageableDTO: { sortField: "descricao", sortDir: "ASC" },
                    codigoComunidadeAutonoma
                }
            }
        );
        return response.data.data.findAllEspanhaProvincia;
    }

    @action async findEspanhaMunicipio(variables) {
        try {
            const response = await Api.post('',
                {
                    query: `
                        query ($codigoProvincia: String, $pageableDTO: PageableDTOInput, $descricao: String){
                            findAllEspanhaMunicipio(codigoProvincia: $codigoProvincia, pageableDTO: $pageableDTO, descricao: $descricao){
                                last
                                content {
                                    id
                                    codigoMunicipio
                                    descricao
                                }               
                            }
                        }`,
                    variables: variables
                })

            const { last, content } = response.data.data.findAllEspanhaMunicipio;

            const customContent = content.map(item => {
                const descricaoCapitalize = string.capitalize(item.descricao)

                return ({
                    ...item,
                    value: item.id,
                    label: descricaoCapitalize
                })
            });

            return {
                last,
                content: customContent
            };
        } catch (error) {
            throw error;
        }
    }

    @action async getLogoUnidadeLogada() {
        try {
            const response = await Api.post('', {
                query: `{
                    findUnidadeLogadaInformacoesPublicas{
                        fotoPerfil
                        id
                    }
                }
                `
            })

            return response.data.data.findUnidadeLogadaInformacoesPublicas;

        } catch (error) {
            throw error;
        }
    }

    @action async checkUnidadeUtilizaFotoPerfilComoLogo() {
        try {
            const response = await findByUnidadeLogadaConfiguracaoUnidade();
            if (response.data.errors) {
                throw Error('Falha ao carregar configurações da unidade');
            }

            const { utilizaFotoUnidadeGuiaSadt } = response.data.data.findByUnidadeLogadaConfiguracaoUnidade;
            return utilizaFotoUnidadeGuiaSadt;
        } catch (error) {
            throw error;
        }
    }

}

import { inject } from "mobx-react";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import string from "../../../../../utils/string";
import { Button } from "../../../../../components/ui/Buttons";
import CheckMarkIcon from "../../../../../components/Icon/CheckMarkIcon";
import { orcamentoDefault } from "../../../../../stores/Orcamento.store";
import ModalOrcamentoOdontologico from "../Modal/Orcamento/ModalOrcamentoOdontologico";
import ModalConfirmacao from "../../../../../components/Modal/ModalConfirmacao/ModalConfirmacao";

const Orcamento = observer(
  ({
    classes,
    history,
    odontogramaStore,
    extratoStore,
    prontuarioStore,
    sujeitoAtencaoStore,
    orcamentoStore,
    unidadeStore,
  }) => {
    const { dadosModalConfirmacao } = odontogramaStore;
    const { orcamento } = orcamentoStore;
    const { open } = dadosModalConfirmacao;
    const { sujeitoAtencaoSelected } = prontuarioStore;
    const { orcamentoProcedimentos, profissionalSaude } = orcamento;

    const [openModalOrcamento, setOpenModalOrcamento] = useState(false);

    const handleOpenOrcamentos = () => {
      sujeitoAtencaoStore.reset();
      sujeitoAtencaoStore.changeId(sujeitoAtencaoSelected.id);
      history.push(
        `/sujeito-atencao/edit/${sujeitoAtencaoSelected.id}/orcamentos`
      );
    };

    const handleOpenModalOrcamento = () => {
      orcamentoStore.orcamento = orcamentoDefault;
      setOpenModalOrcamento(true);
    };

    const handleClose = () => {
      setOpenModalOrcamento(false);
      orcamentoStore.orcamento = orcamentoDefault;
      dadosModalConfirmacao.open = false;
    };

    const handleAbrirFinanceiro = () => {
      const procedimentosSelecionados = orcamentoProcedimentos.filter(
        (item) => item.checked
      );
      extratoStore.preencherDadosOrcamentoOdontologico({
        tipo: "RECEITA",
        sujeitoAtencao: sujeitoAtencaoSelected,
        profissionalSaude,
        procedimentos: procedimentosSelecionados,
        orcamento: orcamentoDefault,
      });
      history.push("/financeiro");
      dadosModalConfirmacao.open = false;
    };

    const handleClickFaturar = async() => {
      try {
        await unidadeStore.inicializaConfiguracaoUnidade();

        const { configuracaoUnidade } = unidadeStore;
        const { azulControleAtivado, utilizaFinanceiroAppHealth } =
          configuracaoUnidade || {};

        const showFinanceiroAzulControle =
          azulControleAtivado && !utilizaFinanceiroAppHealth;

        showFinanceiroAzulControle
          ? await handleFaturarFinanceiroCompleto()
          : await handleFaturarFinanceiroSimples(orcamento);

      } catch (e) {
        console.error(e);
      }
    };

    const handleFaturarFinanceiroCompleto = async () => {
      try {
        await orcamentoStore.handleFaturarFinanceiroCompleto(orcamento.id);
        dadosModalConfirmacao.open = false;
      } catch (e) {
        console.error(e);
      }
    };

    const handleFaturarFinanceiroSimples = async (data) => {
      try {
        await orcamentoStore.getDadosOrcamentoOdontologico(
          data.id,
          sujeitoAtencaoSelected.id,
        );
        const {
          profissionalSaude,
          valor,
          desconto,
          tipoDesconto,
          orcamentoProcedimentos,
        } = orcamentoStore.orcamento || {};

        const orcamento = {
          ...orcamentoStore.orcamento,
          subtotal: string.calculaDesconto(valor, desconto, tipoDesconto),
        };

        const procedimentos = orcamentoProcedimentos.filter(item => item.checked).map(item => {
          const procedimento = item.procedimento?.id? item.procedimento : item.procedimentoOdontologico?.procedimento;

          return procedimento;
        })

        extratoStore.preencherDadosOrcamentoOdontologico({
          tipo: "RECEITA",
          sujeitoAtencao: sujeitoAtencaoSelected,
          ...(profissionalSaude?.id && {
            profissionalSaude: {
              ...profissionalSaude,
              label: profissionalSaude.nome,
              value: profissionalSaude.id,
            },
          }),
          procedimentos,
          orcamento: orcamento,
        });
        history.push("/financeiro");
        dadosModalConfirmacao.open = false;
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <div className={classes.content}>
        <Button bgColor="#707C97" onClick={handleOpenOrcamentos}>
          Ver orçamentos
        </Button>
        <Button bgColor="#5462E0" onClick={handleOpenModalOrcamento}>
          Emitir orçamento
        </Button>
        {openModalOrcamento && (
          <ModalOrcamentoOdontologico
            openModalOrcamento={openModalOrcamento}
            setOpenModalOrcamento={setOpenModalOrcamento}
            sujeitoAtencaoSelected={sujeitoAtencaoSelected}
            loadList={odontogramaStore.findAllProcedimentoOdontologico}
          />
        )}
        {open && (
          <ModalConfirmacao
            open={open}
            icon={<CheckMarkIcon />}
            onClose={() => (dadosModalConfirmacao.open = false)}
            color={"green"}
            title={"Orçamento aprovado! Deseja faturar?"}
            buttons={{
              labelPrimaryButton: "Fatura depois",
              labelSecondButton: "Faturar agora",
              colorCustomPrimaryButton: "transparency",
              colorCustomSecondButton: "green",
              handlePrimaryButton: () => handleClose(),
              handleSecondButton: () => handleClickFaturar(),
            }}
          ></ModalConfirmacao>
        )}
      </div>
    );
  }
);

const styles = {
  content: {
    display: "flex",
    gap: "8px",
  },
};

const OrcamentoWithStyles = withStyles(styles)(Orcamento);
export default inject(
  "odontogramaStore",
  "extratoStore",
  "prontuarioStore",
  "sujeitoAtencaoStore",
  "orcamentoStore",
  "unidadeStore",
)(OrcamentoWithStyles);

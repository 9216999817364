import { inject } from "mobx-react";
import { useEffect } from "react";
import { 
    applyInternationalPhoneMask, 
    applyPhoneMask, 
    applyPhoneEspanhaMask 
} from "../../utils/PhoneMask"; 

export const formattedPhone = ({telefone, telefoneDDI, utilizaTelefoneInternacional}) => {
    const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);

    if(!telefone){
        return ''
    };

    if(utilizaTelefoneInternacional){
        return telefoneDDI ? applyInternationalPhoneMask(telefone, telefoneDDI) : '';
    } 
    return unidadeLogada?.rede?.paisAtuacao?.descricao === "ESPANHA" ? applyPhoneEspanhaMask(telefone) : applyPhoneMask(telefone) 
    
};

const TelefoneFormatado = ({telefone, telefoneDDI, unidadeStore, checkTelefoneInternacional}) => {
    useEffect(() => {
        !checkTelefoneInternacional && checkUsoTelefoneInternacional();
    }, []);

    const checkUsoTelefoneInternacional = async() => {
        await unidadeStore.checkUsoTelefoneInternacional();
    };

    const utilizaTelefoneInternacional = unidadeStore?.unidade?.utilizaTelefoneInternacional;   

    return formattedPhone({telefone, telefoneDDI, utilizaTelefoneInternacional});
    
};

export default inject("unidadeStore")(TelefoneFormatado);
import React from 'react'
import PropTypes from "prop-types"
import classnames from "classnames";
import moment from "moment";
import CallIcon from "@material-ui/icons/Call"
import {Tooltip} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";

import itemListaPacientenStyle from '../assets/jss/components/itemListaPacienteStyle'
import IconeAniversario from '../components/Prontuario/iconeAniversario'
import GracefulImage from './Image/GracefulImage';

class ItemListaPaciente extends React.Component {
    getMinutosAguardando = () => {
        const { paciente } = this.props;
        const dataChegadaSujeitoAtencao = typeof paciente?.dataChegadaSujeitoAtencao === 'string' && (moment(paciente.dataChegadaSujeitoAtencao)) ;
        const dataFinalEspera = typeof paciente?.dataInicioAtendimento === 'string' ? moment(paciente.dataInicioAtendimento) : moment();
        const tempoTotalAguardando =  moment(dataFinalEspera).diff(dataChegadaSujeitoAtencao, 'miliseconds');
        const minutosAguardando = dataChegadaSujeitoAtencao && (dataFinalEspera.diff(dataChegadaSujeitoAtencao, 'minutes'));
        const segundosAguardando = dataChegadaSujeitoAtencao && (dataFinalEspera.diff(dataChegadaSujeitoAtencao, 'seconds'));
        const horasAguardando = dataChegadaSujeitoAtencao && (dataFinalEspera.diff(dataChegadaSujeitoAtencao, 'hours'));

        if (!dataChegadaSujeitoAtencao || tempoTotalAguardando < 0) {
            return ' 00 : 00 : 00 '
        }

        const segundos = segundosAguardando ? moment.duration(segundosAguardando, 'seconds').get('seconds') : '0';
        const minutos = minutosAguardando ? moment.duration(minutosAguardando, 'minutes').get('minutes') : '0';
        const horas = horasAguardando ? moment.duration(horasAguardando, 'hours').get('hours') : '0';

        const doisDigitosHoras = `${horas}`.padStart(2, '0');
        const doisDigitosMinutos = `${minutos}`.padStart(2, '0');
        const doisDigitosSegundos = `${segundos}`.padStart(2, '0');

        return (`${doisDigitosHoras} : ${doisDigitosMinutos} : ${doisDigitosSegundos}`);
    };

    renderCallButton() {
        const { onCall, paciente, classes } = this.props;

        if (paciente.dataInicioAtendimento && paciente.dataFimAtendimento) {
            return null;
        }

        return (
            <CallIcon className={classes.icon} onClick={onCall} />
        )
    }

    render() {
        const { classes, onClick, paciente, color, image, aniversario, selecionado, open } = this.props;
        const { nome } = paciente || {};

        const classItem = classnames(
            paciente.selected && classes.itemSelected,
            paciente.selected && color,
        );

        const classItemMinimizado = classnames(
            classes.itemMinimizado,
            paciente.selected && classes.itemSelectedMinimizado,
            color && classes[`${color}Status`],
        );

        const classTipo = classnames(
            classes.textInfo,
            classes.textTipo,
        );

        const minutosAguardando = this.getMinutosAguardando();

        return (
        <div>
            { open ?
                <div className={classnames(classes.border, color && classes[`${color}Status`])} onClick={onClick}>
                    <div className={classnames(classes.item, classItem)}>
                        <div className={classes.imageIconeAniversario}>
                            {image ?
                                <GracefulImage src={image} alt={paciente.nome} className={classes.image} />
                                : <div className={classes.image} />
                            }
                            <IconeAniversario
                                aniversario={aniversario}
                                selecionado={selecionado}>
                            </IconeAniversario>
                        </div>
                        <div className={ classes.info }>
                            <div>
                                <div className={classes.textInfoContainer}>
                                    <Tooltip title={nome.length > 16 ? nome : ""} placement="top">
                                        <p className={classnames(classes.textInfo, classes.nomePaciente)}>{nome} </p>
                                    </Tooltip>
                                    <div className={classes.horaTipo}>
                                        <Tooltip title={paciente.tipo?.descricao.length > 11 ? paciente.tipo?.descricao : ""} placement="top">
                                            <p className={classnames(classes.textInfo, classes.maxWidthTipo, classes.centraliza)}> {paciente.tipo?.descricao} </p>
                                        </Tooltip>
                                        {paciente.horaInicio && <p className={classnames(classes.textInfo, classes.centraliza)}> às </p>}
                                        <p className={classnames(classTipo, classes.colorFont)}>{paciente.horaInicio ?  paciente.horaInicio : 'Encaixe'}</p>
                                    </div>
                                    <p className={classTipo}>Esperando à {minutosAguardando}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={ classes.gridItemMinimizado} >
                    <div className={classItemMinimizado} onClick={onClick}>
                        <div className={classes.imageIconeAniversario}>
                            {image ?
                                <Tooltip title={paciente.nome} placement="right">
                                <GracefulImage src={image} alt={paciente.nome} className={classnames(classes.imageMinimizado, classes.image)} />
                                </Tooltip>
                                : <div className={classnames(classes.imageMinimizado, classes.image)} />
                            }
                            <IconeAniversario
                                aniversario={aniversario}
                                selecionado={selecionado}
                                isDrawerMinimizado={true}
                            >
                            </IconeAniversario>
                        </div>
                    </div>
                 </div>
            }
        </div>
        )
    }

}

ItemListaPaciente.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    selected: PropTypes.bool
};

export default withStyles(itemListaPacientenStyle)(ItemListaPaciente);
import React from "react";
import Dialog from "./Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonAlert from "../Button/ButtonAlert";
import ButtonClearPrimary from "../Button/ButtonClearPrimary";
import InputForm from "../Input/InputForm";
import GridItem from '../GridItem'
import { withStyles, CircularProgress, Grid } from "@material-ui/core";
import { tokenMask, reomveInputSpecialChars } from "../../utils/inputFormMasks";
import InfoIconFilled from "../Icon/InfoIconFilled";
import AlertModal from "../Modal/AlertModal";


const buttonStyle = {
  width: 120,
  height: 38,
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
  borderRadius: 85
};

const styles = theme => ({
  alertContainer: {
    maxWidth: 304,
    maxHeight: 250
  },
  alertTitle: {
    "& h6": {
      textAlign: "left",
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 20,
    },
    paddingBottom: 5
  },
  alertDescription: {
    lineHeight: "normal",
    textAlign: "left",
    fontSize: 12,
    color: theme.palette.commons.fontColor
  },
  errorDescription: {
    lineHeight: "normal",
    textAlign: "left",
    fontSize: 10,
    paddingTop: 5,
    color: theme.palette.commons.red
  },
  containerButtons: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 12
  },
  buttonCancel: {
    ...buttonStyle,
    marginRight: 5,
    background: '#FAFAFC'
  },
  buttonOk: {
    ...buttonStyle,
    marginLeft: 5,
    background: '#26ACA9',
    "&:hover": {
      background: '#218785'
    }
  },
  inputBorder: {
    borderRadius: '100px',
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 5,
    fontSize: 20,
    background: '#F2F2F2',
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box'
  },
  input: {
    lineHeight: "normal",
    textAlign: "center",
    width: '100%',
    marginBottom: '5% !important',
  }
});

const InputTokenDialog = ({
  inputValue,
  onChange,
  open,
  onClose,
  onCancel,
  onOk,
  classes,
  loading,
  error,
  canPrintSigned,
  history
}) => { 
  if(!canPrintSigned) {
   return ( <AlertModal
      open={open}
      onClose={onCancel}
      icon={<InfoIconFilled color='white' size={24} />}
      title={'Erro ao gerar a assinatura digital, dados incompletos.'}
      withButtons
      bgIconColor={'#FB7676'}
      buttonOptions={{
        onCancel: onCancel,
        confirmText: 'Preencher os dados',
        onConfirm: () => history.push('/perfil'),
      }}
    >
      <span style={{  color: '#505050' }}>
        Preencha o <strong>número do conselho</strong> e <strong>UF Conselho</strong>.
      </span>
    </AlertModal>
    )
  }
  return(
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.alertContainer}>
        <DialogTitle className={classes.alertTitle}>Autenticação</DialogTitle>
        <DialogContent className={{ padding: '0 24px 10px'}}>
          <DialogContentText className={classes.alertDescription}>
            Verifique o token de autenticação no aplicativo configurado em seu celular.
          </DialogContentText>
          <DialogContentText className={classes.errorDescription}>
          {error && '*digite um tolken válido' }
          </DialogContentText>
        </DialogContent>

        <Grid container className={classes.alertDescription}>
          <GridItem xs={12}>
            <InputForm name={"token"}
              placeholder={"Token"}
              value={inputValue || ""}
              className={classes.input}
              alternativeInputClass={classes.inputBorder}
              onChange={onChange}
              inputComponent={tokenMask}
              onClick={reomveInputSpecialChars}
              error={error}
              autoFocus />
          </GridItem>
        </Grid>

        <DialogActions style={{ textAlign: "center" }}>
          <Grid container className={classes.containerButtons}>
            <Grid item xs={12}>
              <ButtonClearPrimary
                className={classes.buttonCancel}
                onClick={onCancel}
                disabled={loading}
              >
                <React.Fragment>
                  Cancelar
                {loading && (
                    <CircularProgress
                      color="primary"
                      size={12}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </React.Fragment>
              </ButtonClearPrimary>
              <ButtonAlert
                className={classes.buttonOk}
                onClick={onOk}
                disabled={loading}
              >
                <React.Fragment>
                  Continuar
                {loading && (
                    <CircularProgress
                      color="inherit"
                      size={12}
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </React.Fragment>
              </ButtonAlert>
            </Grid>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  )};

export default withStyles(styles)(InputTokenDialog);

import React from 'react';
import {Button} from '../../../components/ui/Buttons';

const FinalVigencia = ({salaCalendar})=>{

  const styles = { 
    content: {
      display:"flex",
      flexDirection:"column",
      width:"332px",
      justifyContent:"center",
      margin:"0 auto",
      gap:"16px",
      alignItens:"center",
      height:"100%",
      alignItems:"center"
    },
    title:{
      fontSize:"24px",
      weight:600,
      textWrap:"noWrap"
    },
    contentText:{
      fontSize:"18px",
      textAlign:"center",
    }
  }

  const handleClickRedirect=(e)=>{
    if(!salaCalendar){
      window.location.href='/#/configuracoes/horario-atendimento';
    }else{
      window.location.href='/#/configuracoes/horario-salas'
    }
  }

  return (
    <div style={styles.content}>
        <h1 style={styles.title}>Sua agenda está expirada!</h1>
        <span style={styles.contentText}>Continue a agendar seus pacientes, configure a sua agenda</span>
      <Button width={"272px"} onClick={handleClickRedirect}>Configurar agenda</Button>
    </div>
  )
}

export default FinalVigencia ;
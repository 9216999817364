import { withStyles } from "@material-ui/core";
import { inject } from "mobx-react";
import React from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import String from "../../utils/string";
import ItemList from "../../components/Modal/ItemList";
import Colors from "../../template/Colors";
import Scroll from "../../components/InfiniteScroll/Scroll";

const AtendimentoModalTabAgendamentos = (props) => {
  const { atendimentoStore, classes, atendimentosSujeitoSelecionado, loadMore, last } = props;

  const redes = JSON.parse(localStorage['_immortal|redes']);
  const isMultiClinica = redes.length > 1;

  const openModalInfosAgendamento = (atendimento) => {
    atendimentoStore.changeFieldsStore({
      infosConsultaSelecionada: atendimento,
      openInfosAgendamentoModal: true,
    });
  };

  const getStyle = (color) => {
    return {
      background: color,
      width: '5px',
      height: '36px',
      borderRadius: '100px 0 0 100px',
      marginRight: '4px',
    }
  }

  return (
    <div className={classes.contentList}>
      <Scroll 
        loadMore={loadMore}
        pageStart={0}
        hasMore={!last}
      >
        {atendimentosSujeitoSelecionado.map((atendimento) => {
          const valueSalaTipo = atendimento.sala
          ? atendimento.sala.nome
          : String.capitalize(atendimento.tipo?.descricao || "")
          
          return(
          <Grid
            container
            direction={"column"}
            wrap={"nowrap"}
            key={atendimento.id}
            onClick={() => openModalInfosAgendamento(atendimento)}
          >
            <Grid
              container
              className={classes.listItem}
            >
              {
                isMultiClinica && <div style={getStyle(Colors.status[atendimento.situacao.toLowerCase()])} />
              }
              <ItemList
                size={isMultiClinica ? 4 : 5}
                field={"Data consulta:"}
                value={moment(atendimento.data).format("DD/MM/YYYY")}
                lineBottom={false}
                classes={{
                  listItem: classes.listItemMargin,
                  itemTitle: classes.listItemTitle,
                  itemValue: classes.listItemValue,
                }}
              />
              <ItemList
                tooltipTitle={isMultiClinica && valueSalaTipo}
                size={3}
                lineBottom={false}
                field={atendimento.sala ? "Sala:" : "Tipo"}
                value={valueSalaTipo}
                classes={{
                  listItem: classes.listItemMargin,
                  itemTitle: classes.listItemTitle,
                  itemValue: classes.listItemValue,
                }}
              />

              { isMultiClinica ? 
                <ItemList
                  tooltipTitle={atendimento.unidade?.nome}
                  size={4}
                  lineBottom={false}
                  field={"Unidade:"}
                  value={atendimento.unidade?.nome}
                  classes={{
                    listItem: classes.listItemMargin,
                    itemTitle: classes.listItemTitle,
                    itemValue: classes.listItemValue,
                  }}
                />
              :
              <ItemList
                size={3}
                lineBottom={false}
                field={"Situação:"}
                color={Colors.status[atendimento.situacao.toLowerCase()]}
                classes={{
                  listItem: classes.listItemMargin,
                  itemTitle: classes.listItemTitle,
                }}
              />}
            </Grid>
          </Grid>
        )})}
      </Scroll>
    </div>
  );
};

const styles = {
  contentList: {
    height: "calc(100% - 32px)",
    overflow: "auto",
  },
  listItem: {
    alignItems: 'center',
  },
  listItemTitle: {
    fontSize: 10,
    color: Colors.commons.gray7,
  },
  listItemValue: {
    fontSize: 14,
    color: Colors.commons.gray9,
    height: 20,
  },
};

const AtendimentoModalTabAgendamentosWithStyles = withStyles(styles)(
  AtendimentoModalTabAgendamentos
);
export default inject("atendimentoStore")(
  AtendimentoModalTabAgendamentosWithStyles
);

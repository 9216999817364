import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import classNames from "classnames";
import queryString from "query-string";
import styles from "./LoginStyles";
import { observer } from "mobx-react-lite";
import { Auth } from "../../config/api";
import config from "../../config/config";
import { findUnidadeLogada } from "../../services/UnidadeService";
import string from "../../utils/string";
import { InputCpf } from "./InputCpf";
import PageTitle from "../../components/PageTitle/PageTitle";
import LoginRecuperarSenha from "./LoginRecuperarSenha";
import ButtonLong from "../../components/Button/ButtonLong";
import InputSenha from "../../components/Input/InputSenha";
import Notification from "../../components/Notification";
import useMediaQuery from "../../utils/useMediaQuery";
import LoginMobile from "./LoginMobile";

const querystring = require("querystring");

const Login = observer((props) => {
  const {
    loginStore,
    usuarioStore,
    unidadeStore,
    socketStore,
    chatStore,
    location,
    history,
    classes,
  } = props;

  const matches = useMediaQuery('(max-width: 580px)')

  const [credentials, setCredentials] = useState({ login: "", password: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loginStore.resetNotification();

    const urlParams = queryString.parse(location.search);

    if (urlParams["redefinir-senha"]) {
      loginStore.openNotification(
        "Sua senha foi redefinida com sucesso.",
        "success"
      );
    }

    if (urlParams["documento"]) {
      this.setState({
        credentials: { ...credentials, login: urlParams.documento },
      });
    }
  }, []);

  const handleChange = (e, field) => {
    setCredentials({
      ...credentials,
      [field]: e.target.value,
    });
  };
  
  const onKeyPressEnter = (e) => {
    e.key === 'Enter' && onClickLogin();
  };

  const onClickLogin = async (e) => {
    setLoading(true);
    Auth.post(
      "oauth/token",
      querystring.stringify({
        grant_type: "password",
        username: string.removeSpecialChars(credentials.login),
        password: credentials.password,
        client_id: config.clientId,
      })
    )
      .then(async (response) => {
        await loginStore.saveTokens(response.data);

        loginStore.getUser().then(async (response) => {
          const redes = response.data.data.obterRedesUsuarioLogado;

          const unidadeUnicaExpirada = redes && redes?.length === 1 && redes[0]?.unidades?.length === 0 && redes[0].unidadesVencidas?.length === 1;
          const goToSelectUnidadesPage = redes && (redes?.length > 1 || redes[0]?.unidades?.length > 1 || unidadeUnicaExpirada); 

          if (goToSelectUnidadesPage) {
            history.push({
              pathname: "/select-rede",
              state: { redes },
            });

            return;
          }
          
          usuarioStore.isAuthenticated = true;
          
          if (redes.length === 0) {
            history.push({
              pathname: "/sem-rede",
            });
            
            return;
          }
          
          await findUnidadeLogada();
          await unidadeStore.checkUsoTelefoneInternacional();
          await unidadeStore.inicializaConfiguracaoUnidade();
          const profissionalSaudeLogado = await usuarioStore.findProfissionalSaudeLogado();

          if (profissionalSaudeLogado?.tipo === 4) {
            history.push("/atendimento");
          } else {
            history.push("/");
          }
          setLoading(false);
        });
      })
      .catch((response) => {
        usuarioStore.isAuthenticated = false;
        loginStore.loginError(response.response);
        setLoading(false);
      })
  };

  return (
    <div>
      <PageTitle title="Login" />
      { matches ? 
      <LoginMobile/>
      :
      <LoginRecuperarSenha>
        <div className={classes.content}>
          <div className={classes.titulo}>Entrar</div>
          <div className={classes.panel}>
            <div>
              <div className={classes.titleInput}> CPF </div>
              <InputCpf
                value={credentials.login}
                classInputRoot={classes.inputStyle}
                onChange={(e) => handleChange(e, "login")}
              />
            </div>
            <div>
              <div className={classes.titleInput}> Senha </div>
              <InputSenha
                className={classNames(classes.inputStyle, classes.inputSenha)}
                placeholder="Senha"
                value={credentials.password}
                onChange={(e) => handleChange(e, "password")}
                onKeyPress={onKeyPressEnter}
              />
            </div>

            <Link
              to="redefinir-senha"
              className={classes.esqueciMinhaSenhaLinkStyle}
            >
              Esqueci minha senha
            </Link>

            <ButtonLong
              colorCustom="green"
              type="submit"
              loading={loading}
              disabled={loading}
              className={classes.buttonStyle}
              onClick={onClickLogin}
            >
              Entrar
            </ButtonLong>
          </div>
        </div>
      </LoginRecuperarSenha>}
      <Notification
        close={(event, reason) => loginStore.closeNotification(event, reason)}
        reset={() => loginStore.resetNotification()}
        isOpen={loginStore.notification.isOpen}
        variant={loginStore.notification.variant}
        message={loginStore.notification.message}
      />
    </div>
  );
});

const LoginWithStyles = withStyles(styles)(Login);
const stores = ['loginStore', 'usuarioStore', 'chatStore', 'unidadeStore', 'socketStore'];
export default inject(...stores)(LoginWithStyles);

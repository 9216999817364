import React from "react";
import moment from "moment";
import { Fab, Grid } from "@material-ui/core";
import Dialog from "../Dialog/Dialog";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import PaymentIcon from '@material-ui/icons/Payment';
import string from "../../utils/string";

const styles = () => ({
  root: {
    margin: "36px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  dialogPaper: {
    minHeight: "268px",
    minWidth: "449px",
  },
  title: {
    fontSize: "18px",
    color: "#26ACA9",
    fontWeight: 700,
    maxWidth: "230px",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  label: {
    display: "flex",
    alignItems: "center",
    color: "#505050",
    fontSize: "14px",
    fontWeight: 400,
    margin: "9px 0",
    "& > span": {
      marginLeft: "8px"
    }
  },
  subtitle: {
    color: "#26ACA9",
    fontSize: "12px"
  },
  content: {
    marginTop: "10px"
  },
  closeIcon: {
    width: "40px",
    height: "40px",
    marginLeft: "8px",

    color: "#fff",
    boxShadow:
      "4px 4px 15px rgba(38, 172, 169, 0.05), 2px 2px 10px rgba(38, 172, 169, 0.1), 1px 1px 50px rgba(38, 172, 169, 0.15);",
    backgroundColor: "#FB7676",
    "&:hover": {
      backgroundColor: "#c4454a"
    }
  }
});

const InfosAgendamentoModal = ({ open, agendamento, classes, onClose }) => {
  return (
    <>
      <Dialog
        onClose={onClose}
        hideBackdrop
        classes={{ paper: classes.dialogPaper }}
        open={open}
        disableBackdropClick
      >
        <div className={classes.root}>
          <Grid className={classes.header}>
            <span className={classes.title}> Informações da consulta </span>
            <Grid>
              <Fab className={classes.closeIcon} onClick={onClose}>
                <CloseIcon />
              </Fab>
            </Grid>
          </Grid>
          <Grid container className={classes.content}>
            <Grid xs={6}>
              <Grid className={classes.label}>
                <EventIcon />
                <span>
                  {moment(agendamento.data).format("DD / MM / YYYY")}
                </span>
              </Grid>
              <Grid className={classes.label}>
                <ScheduleIcon />
                <span>
                  {`${agendamento.horaInicio} às ${agendamento.horaFim}`}
                </span>
              </Grid>
              <Grid className={classes.label}>
                <RoomIcon />
                <span>
                  {agendamento.remoto ? 'Remoto' : 'Presencial'}
                </span>
              </Grid>
              <Grid className={classes.label}>
                <PaymentIcon />
                <span>
                  {agendamento.convenio?.descricao ? agendamento.convenio?.descricao : " - "}
                </span>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {!agendamento.sala && (
                <>
                  <Grid>
                    <span className={classes.subtitle}>Tipo de Consulta</span>
                    <Grid className={classes.label}>
                      <AssignmentIcon />
                      <span>
                        {agendamento.tipo?.descricao}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid>
                    <span className={classes.subtitle}>Profissional Saúde</span>
                    <Grid className={classes.label}>
                      <BusinessIcon />
                      <span>
                        {agendamento.profissionalSaude?.nome}
                      </span>
                    </Grid>
                  </Grid>
                </>)}
              {agendamento.sala && (
                <>
                  <Grid>
                    <span className={classes.subtitle}>Sala</span>
                    <Grid className={classes.label}>
                      <MeetingRoomIcon />
                      <span>
                        {agendamento.sala?.nome}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid>
                    <span className={classes.subtitle}>Procedimentos</span>
                    <Grid className={classes.label}>
                      <AssignmentIcon />
                      <span>
                        {string.concatenarArrayProcedimentosEmString(agendamento.procedimentos)}
                      </span>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </Dialog>

    </>
  );
};

export default withStyles(styles)(InfosAgendamentoModal);
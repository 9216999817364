import React from "react";
import {
    withStyles,
    CircularProgress,
    Switch,
  } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { findAllProcedimentos, findByIdProcedimento, procedimentoNomeJaExistente, saveProcedimento } from "../../../services/ProcedimentoService";
import { findAllProduto } from "../../../services/ProdutoService";
import Notification from '../../../components/Notification';
import { findAllConvenio } from '../../../services/ConvenioService';
import string from '../../../utils/string';
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import { tabs } from "../../../stores/ModalConfiguracoes.store";
import { findAllProcedimentoAuditByProcedimentoId, findAllProcedimentoAuditByUnidade } from "../../../services/AuditoriaService";
import HeaderConfiguracoesButtonNovo from "../Components/HeaderConfiguracoesButtonNovo";
import ContentConfiguracoes from "../Components/ContentConfiguracoes";
import Table from "../../../components/Table/Table";
import { columnsProcedimentos, optionsTipo, tabsModal } from "../Constants/Constants";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import AddIcon from "../../../components/Icon/AddIcon";
import ModalConfiguracoesTabs from "../Components/Modal/ModalConfiguracoesTabs";
import { TextField, TextFieldSearch } from "../../../components/TextField";
import ConvenioList from "../Components/Modal/Tabs/ConvenioList";
import ProdutoList from "../Components/Modal/Tabs/ProdutoList";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import WarningIcon from "../../../components/Icon/Warning";
import { Button } from "../../../components/ui/Buttons";

const procedimentoSelectedDefault = {
    id: null,
    nome: "",
    status: true,
    tuss22ProcedimentoEventoSaude: null,
    convenioProcedimentos: [],
    convenioAgendamentoTipo: [],
    produtoProcedimento: [],
    tiposProcedimentos: optionsTipo[0],
};

@inject('SADTStore', 'prontuarioStore', 'modalConfiguracoesStore', 'auditoriaStore', 'procedimentoStore')
@observer
class Procedimentos extends React.Component {
    state = {
        notification: {
            isOpen: false,
            message: "",
            variant: "",
        },
        search: "",
        isLoading: false,
        procedimentos: [],
        procedimentoSelected: procedimentoSelectedDefault,
        loadingSave: false,
        status: true,
        tipoOpcao: "convenio",
        ordenarNome: true,
        ordenarStatus: true,
        pageNumber: 0,
        last: false,
        pageNumberModal: 0,
        loadingProcedimentos: false,
        lastConvenio: false,
        disableButton: false,
        loadingModal: false,
        convenios: [],
        produtos: [],
        ordenarTabela: {
            sortField: "nome",
            sortDir: "ASC",
        },
        tabSelected: tabs.INFORMACOES,
        propsTabsModal: {
            options: ["Convênio", "Produto"],
            tabSelected: tabsModal.CONVENIO,
            valueSearch: "",
            quantidades: [0, 0],
        },
        openAlertProcedimentos: false,
        propsTabsAuditoria: {
            tabSelected: tabs.INFORMACOES
        },
    }
    componentDidMount() {
        const { modalConfiguracoesStore } = this.props;
        this.loadProcedimentos();
        this.setAuditoriaTela();

        modalConfiguracoesStore.titulo="procedimento";
        modalConfiguracoesStore.opcao="procedimento";
    };

    changeTabSelectedModal = async(tabSelected) => {
        const { propsTabsModal } = this.state;

        this.setState(
            {
              convenios: [],
              produtos: [],
              pageNumberModal: 0,
              lastModal: false,
              tipoOpcao: tabSelected === 0 ? "convenio" : "produto",
              propsTabsModal: {
                ...propsTabsModal,
                tabSelected: tabSelected,
                valueSearch: "",
            }
            },
            () =>
            tabSelected === 0
                ? this.loadConvenios()
                : this.loadProdutos()
        );
    };

    setAuditoriaTela = () => {
        const { auditoriaStore } = this.props;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "o procedimento",
            auditoriaTela: "procedimento",
        };

        auditoriaStore.load={
            query: findAllProcedimentoAuditByUnidade, 
            variableName: "",
            variableId: "", 
        };
    };

    loadProcedimentos = () => {
        this.setState({ isLoading: true });
        findAllProcedimentos(
            this.state.status === null ?
            {
                nome: this.state.search || "",
                pageableDTO:{
                    pageNumber: this.state.pageNumber,
                    pageSize: 30,
                    sortDir: this.state.ordenarTabela.sortDir,
                    sortField: this.state.ordenarTabela.sortField
                }
            }
            :
            {
                nome: this.state.search || "",
                ativo: this.state.status,
                pageableDTO:{
                    pageNumber: this.state.pageNumber,
                    pageSize: 30,
                    sortDir: this.state.ordenarTabela.sortDir,
                    sortField: this.state.ordenarTabela.sortField
                }
            }
            ).then((response) => {
            const { last, content } = response.data.data.findAllProcedimentos;
            this.setState({
                procedimentos: [...this.state.procedimentos, ...content],
                last: last
            })
        }).catch(
            error => {
                const notification = {
                    isOpen: true,
                    variant: "error",
                    message: "Erro ao carregar lista de procedimentos"
                };
                this.showAlertMessage(notification);
            })
        .finally(() => {
            this.setState({ isLoading: false })
        })
            
    };

    loadMore = () => {
        const { isLoading, procedimentos } = this.state;
        if (isLoading) {
            return;
        }

        this.setState({ pageNumber: procedimentos.length > 0 ? this.state.pageNumber + 1 : 0 });

        this.loadProcedimentos();
    };

    handleClickSearch = () => {
        this.setState({
            procedimentos: [],
            pageNumber: 0
        }, () => this.loadProcedimentos());
    };

    handleClickClear = () => {
        this.setState({
            search: "",
            procedimentos: [],
            pageNumber: 0,
        }, () => this.loadProcedimentos());
    };

    onChangeSearch = (e) => {
        let search = e.target.value;
        this.setState({ search })
    };

    onKeypressSearch = (e) => {
        if (e.key === 'Enter' && this.state.search.length >= 3) {
            this.setState({
                procedimentos: [],
                pageNumber: 0,
            });
            this.loadProcedimentos();
        }
    };

    handleChangeStatus = async (e) => {
        const { procedimentoSelected } = this.state;
        this.setState({
            procedimentoSelected: {
                ...procedimentoSelected,
                ativo: !procedimentoSelected.ativo
            }
        });
    };

    handleProcedimentoOdontologico = () => {
        const { procedimentoSelected } = this.state;

        const isOdontologico = procedimentoSelected.tiposProcedimentos && procedimentoSelected.tiposProcedimentos.some(item => item.value === "ODONTOLOGICO");
        const tiposProcedimentos = isOdontologico ? [{
            label: "Procedimento",
            value: "PROCEDIMENTO",
          },] : optionsTipo;

        this.setState({
            procedimentoSelected: {
                ...procedimentoSelected,
                tiposProcedimentos,
            }
        });
    };

    setAuditoriaModal = () => {
        const { auditoriaStore } = this.props;
        const { procedimentoSelected } = this.state;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "o procedimento",
            auditoriaTela: "procedimento",
        };

        auditoriaStore.load={
            query: findAllProcedimentoAuditByProcedimentoId, 
            variableName: "procedimentoId",
            variableId: procedimentoSelected.id, 
        };
    };

    handleClickNovoProcedimento = async () =>{
        const { modalConfiguracoesStore } = this.props;
        const { propsTabsModal } = this.state;

        this.setState({ 
            procedimentoSelected: { 
                ativo: true,
                convenioProcedimentos: [],
                produtoProcedimentos: [], 
            },
            tipoOpcao: "convenio",
            propsTabsModal: {
                ...propsTabsModal,
                tabSelected: 0,
                valueSearch: "",
                quantidades: [0, 0]
            },
            convenios: [], 
            pageNumberModal: 0,
        }, () => {
            this.loadConvenios();
            modalConfiguracoesStore.showModal = true;
        });
        
    };

    loadMoreModal = () => {
        const { loadingModal, tipoOpcao, convenios, produtos, pageNumberModal } = this.state;
        if (loadingModal) {
            return;
        };

        const listaOpcao = tipoOpcao === "convenio" ? convenios : produtos;

        this.setState({ pageNumberModal: listaOpcao.length > 0 ? pageNumberModal + 1 : 0 }, () => {
            this.loadListaModal();
        });
    };

    loadListaModal = () => {
        const { tipoOpcao } = this.state;

        tipoOpcao === "convenio" ? this.loadConvenios() : this.loadProdutos();
    };

    alterarOrdemNome = () => {
        this.setState({
            procedimentos: [],
            ordenarNome: !this.state.ordenarNome,
            sortDir: this.state.ordenarNome ? 'DESC' : 'ASC',
            sortField: 'nome',
        });
    };

    alterarOrdemStatus = () => {
        this.setState({
            procedimentos: [],
            ordenarStatus: !this.state.ordenarStatus,
            sortDir: this.state.ordenarStatus ? 'DESC' : 'ASC',
            sortField: 'ativo',
        })
    };

    handleClickOrdenar = async (value) => {
        const { ordenarTabela } = this.state;

        const sortDir = ordenarTabela.sortField !== value ? "ASC" : (ordenarTabela.sortDir === "ASC" ? "DESC" : "ASC")
        const status = value === 'ativo' ? sortDir === 'ASC' : this.state.status;

        this.setState({
            ordenarTabela: { 
              sortDir: sortDir,
              sortField: value,
            },
            procedimentos: [],
            pageNumber: 0,
            status
        }, () => this.loadProcedimentos());
    };

    onCloseModal = () => {
        const { modalConfiguracoesStore } = this.props;

        modalConfiguracoesStore.showModal = false;

        this.setState({ 
            propsTabs: {
                ...this.state.propsTabs,
                valueSearch: "",
            }
         });
    };

    save = procedimento => {
        const { modalConfiguracoesStore } = this.props;

        this.setState({ loadingSave: true });

        saveProcedimento(procedimento)
            .then(() => {
                this.setState({
                    convenioSelected: undefined,
                    propsTabs: {
                        ...this.state.propsTabs,
                        valueSearch: ""
                    },
                    convenios: [],
                    pageNumber: 0,
                    procedimentos: [],
                    pageNumberModal: 0,
                });
                this.showAlertMessage({
                    isOpen: true,
                    variant: "success",
                    message: "Procedimento salvo com sucesso"
                });
                modalConfiguracoesStore.showModal = false;
                this.loadProcedimentos();
            })
            .catch(error => {
                const notification = {
                    isOpen: true,
                    variant: "error",
                    message: "Erro ao salvar o procedimento"
                };
                this.showAlertMessage(notification);
            })
            .finally(() => {
                this.setState({ loadingSave: false });
            });
    };

    handleEditar = async (id, isDuplicar=false) => {
        const { modalConfiguracoesStore } = this.props;
        const selecionado = await findByIdProcedimento(id);
        const procedimento = selecionado.data.data.findByIdProcedimento;
        const valoresComVirgula = procedimento.convenioProcedimentos.map(item => {
            return({
                ...item,
                valor: item.valor ? String(item.valor.toFixed(2)).replace('.', ',') : item.valor,
            })
        });
        const tiposProcedimentos = procedimento.tiposProcedimentos.map(item => ({
            label: string.capitalize(item),
            value: item,
        }))

        const procedimentoAdaptado = {
            ...procedimento,
            tiposProcedimentos,
            convenioProcedimentos: valoresComVirgula
        }

        const selecionadoComId = {
            ...procedimentoAdaptado,
            id: id
        }

        if(procedimento.tuss22ProcedimentoEventoSaude) {
            const {descricao, codigoTermo, codigoTermoDescricao} = procedimento.tuss22ProcedimentoEventoSaude;

            selecionadoComId.tuss22ProcedimentoEventoSaude = {
                ...procedimento.tuss22ProcedimentoEventoSaude,
                descricao: {value: descricao, label: descricao},
                codigoTermo: {value: codigoTermo, label: codigoTermo},
                codigoTermoDescricao: {value: codigoTermoDescricao, label: codigoTermoDescricao}
            }
        };

        if (isDuplicar) {
            selecionadoComId.id=null;
            selecionadoComId.nome=`${selecionadoComId.nome} - cópia`;
        };

        this.setState({ 
            procedimentoSelected: selecionadoComId,
            propsTabsModal: {
                ...this.state.propsTabsModal,
                tabSelected: 0,
                valueSearch: "",
                quantidades: [ selecionadoComId.convenioProcedimentos.length, selecionadoComId.produtoProcedimentos.length ]
            },
            propsTabsAuditoria: {
                tabSelected: 0
            },
            convenios: [], 
            pageNumberModal: 0,
            tipoOpcao: "convenio"
        });

        await this.loadConvenios();
        modalConfiguracoesStore.showModal = true;
    };

    onChangeSearchModal = (e) => {
        const { propsTabsModal } = this.state;

        let value = e.target.value;
        this.setState({ propsTabsModal: {
            ...propsTabsModal,
            valueSearch: value
        } })
    };

    handleClickClearModal = () => {
        this.setState({
            propsTabsModal: {
                ...this.state.propsTabsModal,
                valueSearch: "",
            },
            convenios: [],
            produtos: [],
            pageNumberModal: 0,
        }, () => {
            this.loadListaModal();
        });
    };

    onKeypressSearchModal = (e) => {
        const { propsTabsModal } = this.state;

        if (e.key === 'Enter' && propsTabsModal.valueSearch.length >= 3) {
            this.setState({
                convenios: [],
                produtos: [],
                pageNumberModal: 0,
            }, () => {
                this.loadListaModal();
            });
        }
    };

    handleClickSearchModal = () => {
        this.setState({
            convenios: [],
            produtos: [],
            pageNumberModal: 0,
        }, () => {
            this.loadListaModal();
        });
    }

    loadConvenios = async () => {
        const { propsTabsModal, pageNumberModal, procedimentoSelected } = this.state;
        this.setState({ loadingModal: true });
        await findAllConvenio(
            propsTabsModal.valueSearch,
            {
                sortDir: "ASC",
                sortField: "descricao",
            },
            true,
            pageNumberModal,
            10,
        )
            .then(({ data: response }) => {
                const convenios = response.data.findAllConvenio.content.map(convenioItem => {
                    const convenioChecked = procedimentoSelected?.convenioProcedimentos && procedimentoSelected.convenioProcedimentos.find(item => item.convenio.id === convenioItem.id);
                    const dadosVigente = convenioItem.convenioProcedimentos.find(item => item.procedimento.id === procedimentoSelected.id)
                   
                    return {
                        convenio: {
                            nome: convenioItem.nome,
                            descricao: convenioItem.descricao,
                            id: convenioItem.id,
                            checked: !!convenioChecked,
                        },
                        valor: dadosVigente?.valor || 0,
                        vigencia:{
                            ...dadosVigente?.valorVigente
                        }

                    }
                })

                this.setState({
                    convenios: [...this.state.convenios, ...convenios],
                    lastModal: response.data.findAllConvenio.last
                })
            })
            .catch(error => {
                    const notification = {
                        isOpen: true,
                        variant: "error",
                        message: "Erro ao carregar a lista de convênios."
                    };
                    this.showAlertMessage(notification);
                })
            .finally(() => {
                this.setState({ loadingModal: false })
            })
    };

    loadProdutos = async () => {
        const { propsTabsModal, pageNumberModal, procedimentoSelected } = this.state;
        const { valueSearch } = propsTabsModal;
        this.setState({ loadingModal: true });
        await findAllProduto({
            search: valueSearch,
            ativo: true,
            pageableDTO:{
                pageNumber: pageNumberModal,
                pageSize: 10,
                sortDir: "ASC",
                sortField: "nome"
            }
        })
            .then(({ data: response }) => {
                const produtos = response.data.findAllProduto.content.map(produtoItem => {
                    const produtoChecked = procedimentoSelected.produtoProcedimentos.find(item => item.produto.id === produtoItem.id);
                    return {
                        produto: {
                            nome: produtoItem.nome,
                            descricao: produtoItem.descricao,
                            id: produtoItem.id,
                            checked: !!produtoChecked
                        },
                        quantidade: produtoChecked?.quantidade || 0
                    }
                })


                this.setState({
                    produtos: [...this.state.produtos, ...produtos],
                    lastModal: response.data.findAllProduto.last
                })
            })
            .catch(
                error => {
                    const notification = {
                        isOpen: true,
                        variant: "error",
                        message: "Erro ao carregar a lista de procedimentos"
                    };
                    this.showAlertMessage(notification);
                    
                })
            .finally(() => {
                this.setState({ loadingModal: false })
            })
    }

    handleChangeTuss = (value) => {
        if(value) {
            this.setState(prevState => ({
                procedimentoSelected: {
                    ...prevState.procedimentoSelected,
                    tuss22ProcedimentoEventoSaude: {
                        id: value.id,
                        descricao: {value: value.descricao, label: value.descricao},
                        codigoTermo: {value: value.codigoTermo, label: value.codigoTermo},
                        codigoTermoDescricao: {value: value.codigoTermoDescricao, label: value.codigoTermoDescricao}
                    },
                    nome: value.codigoTermoDescricao
                },
            }));
        } else {
            this.setState(prevState => ({
                procedimentoSelected: {
                    ...prevState.procedimentoSelected,
                    tuss22ProcedimentoEventoSaude: null
                },
            }));
        }
    }

    handleChange = (event, item, index) => {
        const { name, value } = event.target;
        const { procedimentoSelected, tipoOpcao } = this.state;

        const lista =  tipoOpcao === "convenio" ? "convenios" : "produtos";
        const procedimentoConveniosProdutos = tipoOpcao === "convenio" ? "convenioProcedimentos" : "produtoProcedimentos"; 

        if (item) {
            item[name] = 
                name === "valor"
                    ? value
                    : string.numberMaskToFloat(value);

            const indexSelecionado = procedimentoSelected[procedimentoConveniosProdutos].findIndex(
              (convenio) => convenio[tipoOpcao].id === item[tipoOpcao].id
            );
            
            if (indexSelecionado >= 0) {
                let object = procedimentoSelected;
                object[procedimentoConveniosProdutos][indexSelecionado][name] = item[name];
                this.setState({procedimentoSelected: object});
            }

        } else {
            this.setState(prevState => ({
                procedimentoSelected: {
                    ...prevState.procedimentoSelected,
                    [name]: value
                },
            }));
        }

        if(name === "valor" || name === "quantidade"){
            this.setState({
                [lista]: [
                  ...this.state[lista].slice(0, index),
                  {
                    ...this.state[lista][index],
                    [name]: value,
                  },
                  ...this.state[lista].slice(index+1, this.state[lista].length)
                ]
            });
        }
    };

    handleChangeCheckbox = async (item, index) => {
        const { tipoOpcao, procedimentoSelected, propsTabsModal } = this.state;

        const lista = tipoOpcao === "convenio" ? "convenios" : "produtos";
        const isChecked = !item[tipoOpcao].checked;
        item[tipoOpcao].checked = isChecked;

        let updatedProcedimentoSelected = {...procedimentoSelected};

        const updateProcedimentos = (procedimentos, id, value) => {
        if (value) {
            procedimentos.push({ [tipoOpcao]: { id }, ...value });
        } else {
            procedimentos = procedimentos.filter(each => each[tipoOpcao].id !== id);
        }
        return procedimentos;
        };
        if (tipoOpcao === "convenio") {
            updatedProcedimentoSelected.convenioProcedimentos = isChecked ? updateProcedimentos(
                updatedProcedimentoSelected.convenioProcedimentos,
                item.convenio.id,
                { valor: item.valor }
            ) : updatedProcedimentoSelected.convenioProcedimentos.filter(itemConvenio => itemConvenio.convenio.id !== item.convenio.id);
            const quantidades = [
                isChecked ? propsTabsModal.quantidades[0]+1 : propsTabsModal.quantidades[0]-1,
                propsTabsModal.quantidades[1]
            ];
            this.setState({
                propsTabsModal: {
                ...propsTabsModal,
                quantidades
                }
            });
        } else {
        updatedProcedimentoSelected.produtoProcedimentos = isChecked ? updateProcedimentos(
            updatedProcedimentoSelected.produtoProcedimentos,
            item.produto.id,
            { quantidade: item.quantidade }
        ): updatedProcedimentoSelected.produtoProcedimentos.filter(itemProduto => itemProduto.produto.id !== item.produto.id);

        const quantidades = [
            propsTabsModal.quantidades[0],
            isChecked ? propsTabsModal.quantidades[1]+1 : propsTabsModal.quantidades[1]-1
        ];
        this.setState({
        propsTabsModal: {
            ...propsTabsModal,
            quantidades
        }
        });
        }

        const updatedLista = [
        ...this.state[lista].slice(0, index),
        {
            ...this.state[lista][index],
            checked: isChecked,
        },
            ...this.state[lista].slice(index+1)
        ];

        this.setState({ 
            procedimentoSelected: updatedProcedimentoSelected,
            [lista]: updatedLista
        });
    };

    saveProcedimentoComMesmoNome = () => {
        const { procedimentoSelected } = this.state;

        this.setState({
            procedimentoSelected: {
                ...procedimentoSelected,
                nome: `${procedimentoSelected.nome} - cópia`
            }
        }, () => {
            this.closeModalConfirmacao();
            this.handleSave({isSaveMesmoNome: true});
        });
    };

    closeModalConfirmacao = () => {
        this.setState({ openAlertProcedimentos: false })
    }

    voltarMudarNome = () => {
        const { modalConfiguracoesStore } = this.props;
        this.closeModalConfirmacao();
        modalConfiguracoesStore.showModal=true;
    };

    verificaProcedimentoComMesmoNome = async(nomeProcedimento) => {
        const { procedimentoSelected } = this.state;
        const existeMesmoNome = await procedimentoNomeJaExistente(nomeProcedimento);
        this.setState({ 
            openAlertProcedimentos: existeMesmoNome && !procedimentoSelected.id,
        })

        return existeMesmoNome && !procedimentoSelected.id;
    };

    contemErro = () => {
        const { procedimentoSelected } = this.state;
        const { tiposProcedimentos, nome } = procedimentoSelected || {};
        
        const possuiTipoOdontologico = Array.isArray(tiposProcedimentos) && tiposProcedimentos.some(item => item.value === "ODONTOLOGICO");
        const errorNome = !nome || string.isEmpty(nome);
        const errorTuss = possuiTipoOdontologico && !procedimentoSelected?.tuss22ProcedimentoEventoSaude?.id;
    
        const message = {
            nome: "Digite o nome do procedimento.",
            tuss: "O campo código e descrição do procedimento deve ser preenchido."
        };
    
        if (errorNome || errorTuss) {
            const notification = {
                isOpen: true,
                variant: "error",
                message: errorNome ? message.nome : message.tuss
            };
            this.showAlertMessage(notification);
        }
    
        return errorNome || errorTuss;
    };

    handleSave = async ({isSaveMesmoNome=false}) => {
        try{
            const { modalConfiguracoesStore } = this.props;

            let procedimento = this.state.procedimentoSelected;
            this.setState({ 
                disableButton: true,
                loadingSave: true,
            })

            if(this.contemErro()) {
                return 
            }

            const existeMesmoNome = await this.verificaProcedimentoComMesmoNome(procedimento.nome)

            if(!isSaveMesmoNome && existeMesmoNome) return;
    
            if(procedimento.tuss22ProcedimentoEventoSaude) {
                const {id, descricao, codigoTermo} = procedimento.tuss22ProcedimentoEventoSaude;
    
                procedimento.tuss22ProcedimentoEventoSaude = {
                    id,
                    descricao: descricao.value,
                    codigoTermo: codigoTermo.value
                }
            }
    
            const convenioProcedimentosRetiraMascaraValor = procedimento.convenioProcedimentos.map(item => {
                delete item.valores;
                delete item.valorVigente;
                return(
                    {
                        ...item,
                        valor: item.valor ? string.removeMask(item.valor, "R$") : "0"
                    }
                )
            });

            const possuiTiposProcedimentos = procedimento.tiposProcedimentos && procedimento.tiposProcedimentos.length > 0;
    
            procedimento = {
                ...procedimento,
                ...(possuiTiposProcedimentos && {tiposProcedimentos: procedimento.tiposProcedimentos.map(item => item.value)}),
                convenioProcedimentos: convenioProcedimentosRetiraMascaraValor
            };
    
            await saveProcedimento(procedimento);
            this.showAlertMessage({
                isOpen: true,
                variant: "success",
                message: "Procedimento salvo com sucesso"
            });
            modalConfiguracoesStore.showModal = this.state.openAlertProcedimentos;
        }catch{
            const notification = {
                isOpen: true,
                variant: "error",
                message: "Erro ao salvar o procedimento"
            };
            this.showAlertMessage(notification);

        }finally{
            this.setState({
                procedimentos: [],
                pageNumber: 0,
                disableButton: false,
                loadingSave: false,
            }, () =>  this.loadProcedimentos())
           
        }
    };

    handleClickRow = (id) => {
        this.handleEditar(id);
    };

    handleDuplicar = async(id) => {
        this.handleEditar(id, true);
    };

    handleAtivar = async (id) => {
        const { modalConfiguracoesStore } = this.props;
        const selecionado = await findByIdProcedimento(id);
        const procedimento = selecionado.data.data.findByIdProcedimento;

        if (procedimento?.tuss22ProcedimentoEventoSaude?.codigoTermoDescricao) {
            delete procedimento.tuss22ProcedimentoEventoSaude.codigoTermoDescricao;
        }

        const selecionadoAtivo = {
            ...procedimento,
            id,
            ativo: true
        }
        await saveProcedimento(selecionadoAtivo);
        this.setState({
            procedimentos: [],
            pageNumber: 0,
        });
        modalConfiguracoesStore.showModal=false;
        this.loadProcedimentos();
    };

    changeTabModalAuditoriaSelected = async(tabSelected) => {
        const { auditoriaStore } = this.props;
        const { procedimentoSelected } = this.state;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            pageNumber: 0,
            lastPage: false,
            auditorias: [],
        };

        if (tabSelected === 1) {
            auditoriaStore.load={
                query: findAllProcedimentoAuditByProcedimentoId, 
                variableName: "procedimentoId",
                variableId: procedimentoSelected.id,
            }
            await auditoriaStore.loadAuditItems();
          }

        this.setState({
            propsTabsAuditoria: {
                tabSelected: tabSelected
            }
        })
    };

    handleInativar = async (id) => {
        const { modalConfiguracoesStore } = this.props;
        const selecionado = await findByIdProcedimento(id);
        const procedimento = selecionado.data.data.findByIdProcedimento;

        if (procedimento?.tuss22ProcedimentoEventoSaude?.codigoTermoDescricao) {
            delete procedimento.tuss22ProcedimentoEventoSaude.codigoTermoDescricao;
        }

        const selecionadoInativo = {
            ...procedimento,
            id,
            ativo: false
        }
        await saveProcedimento(selecionadoInativo);
        this.setState({
            procedimentos: [],
            pageNumber: 0
        });
        modalConfiguracoesStore.showModal=false;
        this.loadProcedimentos();
    };

	showAlertMessage = (notification) => {
		this.setState({ notification: notification });

		const timeoutId = setTimeout(() => {
			this.closeAlertMessage();
			clearTimeout(timeoutId);
		}, 3000);
	};

	closeAlertMessage = () => {
		const notification = {
			isOpen: false,
			message: "",
		};
		this.setState({ notification: notification });
	};

    handleCodigoTermoDescricaoLoadOptions = async (search, loadedOptions, { page }) => {  
        return this.handleLoadMoreOptionsDescricao({
            search: search,
            loadedOptions,
            data: { page },
            method: "findAllTuss22ProcedimentoEventoSaude",
          });
    };

    handleLoadMoreOptionsDescricao = async ({ search, data, method }) => {
        const { procedimentoStore } = this.props;
        const { procedimentoSelected } = this.state;
        const { tiposProcedimentos } = procedimentoSelected;

        const possuiTipos = tiposProcedimentos && tiposProcedimentos.length > 0;

        let searchFilter = {
            pageableDTO: {
                sortField: "codigoTermo",
                sortDir: "ASC",
                pageNumber: data.page,
                pageSize: 20
            },
            ativo: true,
            ...(possuiTipos && {tiposProcedimentos: tiposProcedimentos.map(item => item.value)})
        }
          
        if(search) {
            searchFilter.search = search;
        }

        const response = await procedimentoStore[method]({...searchFilter});
        return {
            options: response.content,
            hasMore: !response.last,
            additional: {
            page: data.page + 1
          }
        };
    };

    changeTabSelected = async(tabSelected) => {
        const { auditoriaStore } = this.props;
        const { procedimentoSelected } = this.state;

        auditoriaStore.auditoriaProps={
          ...auditoriaStore.auditoriaProps,
          pageNumber: 0,
          lastPage: false,
          auditorias: [],
        }

        if(tabSelected === 1) {
            auditoriaStore.load={
                query: findAllProcedimentoAuditByProcedimentoId, 
                variableName: "procedimentoId",
                variableId: procedimentoSelected.id, 
            }
            await auditoriaStore.loadAuditItems();
        }

        this.setState({ tabSelected })
    };

    renderHeader = () => {
        const { classes } = this.props;
        const { procedimentoSelected } = this.state;

        const verificaProcedimentoOdontologico = () => {
            const { procedimentoSelected } = this.state;
            const { tiposProcedimentos } = procedimentoSelected;
            const isOdontologico = tiposProcedimentos && tiposProcedimentos.some(item => item.value === "ODONTOLOGICO");

            return isOdontologico;
        }

        return(
            <div className={classes.header}>
                <div className={classes.row}>
                    <div className={classes.contentNome}>
                        <div className={classes.titleRenderHeader}>
                        Nome do procedimento *Obrigatório
                        </div>
                        <TextField
                        name={"nome"}
                        className={classes.textField}
                        classes={{
                            root: classes.notchedOutlineTextField,
                        }}
                        placeholder={`Digite o nome do procedimento`}
                        value={procedimentoSelected?.nome}
                        onChange={this.handleChange}
                        />
                    </div>
                    <div className={classes.contentStatus}>
                        <div className={classes.titleField}>Odontológico</div>
                        <Switch
                            checked={verificaProcedimentoOdontologico()}
                            color="primary"
                            onClick={this.handleProcedimentoOdontologico}
                        />
                    </div>
                    <div>
                    {procedimentoSelected?.id && (
                        <div className={classes.contentStatus}>
                            <div className={classes.titleField}>Status</div>
                            <Switch
                                checked={procedimentoSelected?.ativo}
                                color="primary"
                                onClick={this.handleChangeStatus}
                            />
                        </div>
                    )}
                    </div>
                    </div>
                    <div className={classes.titleRenderHeader}>
                        Selecione código e descrição
                    </div>
                    <TextFieldSearch
                        key={`tiposProcedimentos-${procedimentoSelected?.tiposProcedimentos?.length}`}
                        placeholder='Pesquise por código ou descrição'
                        className={classes.renderHeaderInput}
                        classNotched={classes.notchedOutline}
                        loadOptions={this.handleCodigoTermoDescricaoLoadOptions}
                        withPaginate
                        value={procedimentoSelected.tuss22ProcedimentoEventoSaude?.codigoTermoDescricao}
                        onChange={e => this.handleChangeTuss(e)}
                        debounceTimeout={300}
                        additional={{
                        page: 0,
                        }}
                    />
            </div>
        )
    };

    render() {
        const { classes, modalConfiguracoesStore } = this.props;
        const { notification, procedimentos, last, isLoading, search, ordenarTabela, propsTabsModal, loadingSave,procedimentoSelected } = this.state;

        return (
            <>
                <div className={classes.content}>
                    <PageTitle title={pageConfiguracoes("Procedimentos")}/>
                    <HeaderConfiguracoesButtonNovo
                        title="Procedimentos"
                        labelButton="Novo Procedimento"
                        handleClickNovo={this.handleClickNovoProcedimento}
                        search={{
                            handleChange: this.onChangeSearch,
                            value: search,
                            onKeypress: this.onKeypressSearch,
                            handleClickClear: this.handleClickClear,
                            onClickButtonSearch: this.handleClickSearch
                        }}
                    />
                    <div className={classes.contentList}>
                        <ContentConfiguracoes
                            quantidadeProcedimentos={procedimentos.length}
                            setAuditoriaTela={this.setAuditoriaTela}
                        >
                            <div className={classes.table}>
                                <Scroll
                                    loadMore={this.loadMore}
                                    hasMore={!last}
                                    pageStart={0}
                                    initialLoad={false}
                                >
                                    {procedimentos.length > 0 && <Table
                                        dados={procedimentos}
                                        columns={columnsProcedimentos({
                                            headerNome: "Nome do procedimento",
                                            functions: {
                                                handleEditar: this.handleEditar,
                                                handleDuplicar: this.handleDuplicar,
                                                handleDesativar: this.handleInativar,
                                                handleAtivar: this.handleAtivar,
                                            }
                                        })}
                                        clickable={true}
                                        handleClick={this.handleClickRow}
                                        comOrdenacao
                                        ordenarTabela={ordenarTabela}
                                        handleClickOrdenar={(value) => this.handleClickOrdenar(value)}
                                    />}
                                    {
                                        isLoading && (
                                            <div className={classes.notFoundContainer}>
                                                <CircularProgress/>
                                            </div>
                                        )
                                    }
                                </Scroll>
                            </div>

                        </ContentConfiguracoes>
                        <Button
                            shape='circle'
                            bgColor='#26ACA9'
                            onClick={this.handleClickNovoProcedimento}
                            shadow
                            className={classes.buttonCriar} 
                        >
                            <AddIcon/>
                        </Button>
                    </div>
                </div>
                {
                    modalConfiguracoesStore.showModal && 
                    <ModalConfiguracoesTabs
                        itemSelecionado={this.state.procedimentoSelected}
                        title={"Procedimento"}
                        renderHeader={this.renderHeader}
                        onClose={this.onCloseModal}
                        handleSave={this.handleSave}
                        loadingModal={this.state.loadingModal}
                        handleProcedimentoOdontologico={this.handleProcedimentoOdontologico}
                        loadingSave={loadingSave}
                        classes={{
                            content: procedimentoSelected?.id ? classes.contentModalEdit : classes.contentModal
                        }}
                        propsTabs={{
                            ...propsTabsModal,
                            changeTabSelected: this.changeTabSelectedModal,
                            handleClickSearch: this.handleClickSearchModal,
                            handleClickClear: this.handleClickClearModal,
                            onChangeSearch: this.onChangeSearchModal,
                            onKeypressSearch: this.onKeypressSearchModal,
                        }}
                        propsPopperCustom={{
                            handleEditar: this.handleEditar,
                            handleDuplicar: this.handleDuplicar,
                            handleDesativar: this.handleInativar,
                            handleAtivar: this.handleAtivar,
                        }}
                        propsTabsAuditoria={{
                            tabSelected: this.state.propsTabsAuditoria.tabSelected,
                            changeTabAuditoriaSelected: this.changeTabModalAuditoriaSelected,
                        }}
                    >
                        {
                            propsTabsModal.tabSelected === 0 ?
                            <ConvenioList
                                listaModal={this.state.convenios}
                                handleChangeCheckbox={this.handleChangeCheckbox}
                                handleChange={this.handleChange}
                                loadMore={this.loadMoreModal}
                                lastScroll={this.state.lastModal}
                                loadingModal={this.state.loadingModal}
                                procedimentoSelected={procedimentoSelected}
                                loadConvenios= {()=>this.loadConvenios()}
                            />
                            :
                            <ProdutoList
                                listaModal={this.state.produtos}
                                handleChangeCheckbox={this.handleChangeCheckbox}
                                handleChange={this.handleChange}
                                loadMore={this.loadMoreModal}
                                lastScroll={this.state.lastModal}
                                loadingModal={this.state.loadingModal}
                            />
                        }
                    </ModalConfiguracoesTabs>
                }
                <ModalConfirmacao
                    open={this.state.openAlertProcedimentos}
                    classes={{
                        paperAlert: classes.paperAlert
                    }}
                    icon={<WarningIcon/>}
                    color={"yellow"}
                    title={" Já foi criado um procedimento com esse nome"}
                    buttons={{
                        labelPrimaryButton: "Salvar assim mesmo",
                        handlePrimaryButton: () => this.saveProcedimentoComMesmoNome(),
                        labelSecondButton: "Voltar e alterar nome",
                        handleSecondButton: () => this.voltarMudarNome(),
                        disabledPrimaryButton: this.state.loadingSave,
                        disabledSecondButton: this.state.loadingSave,
                    }}
                />
                <Notification
					close={this.closeAlertMessage}
					reset={this.closeAlertMessage}
					isOpen={notification.isOpen}
					variant={notification.variant}
					message={notification.message}
				/>
            </>
        )
    }
};

export default withStyles(theme => ({
    content: {
        background: "#F5F5F5",
        "& #buttonCircle": {
            boxShadow: 'none',
            background: "#fff",
            color: "#5F6368",
        }
    },
    contentModal: {
        height: "calc(100% - 238px) !important",
    },
    contentModalEdit: {
        height: "calc(100% - 278px)",
    },
    contentList: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        fontWeight: 600,
        alignItems: 'center',
        padding: "24px 24px 0",
        gap: '16px',
        height: "calc(100% - 98px)",
    },
    table: {
        "&>div": {
            height: 'calc(100vh - 246px)',
            overflow: 'auto',
        },
        "& thead th": {
            textAlign: "left",
            top: 0,
            position: "sticky",
            zIndex: 1,
        },
    },
    notFoundContainer: {
        width: '100%',
        display: "flex",
        justifyContent: "center",
    },
    buttonCriar: {
        position: 'absolute',
        bottom: 88,
        right: 37,
        zIndex: 100
    },
    titleField: {
        color: "#868686",
        fontSize: "12px",
    },
    header: {
        display: "flex",
        flexDirection: 'column',
    },
    titleRenderHeader: {
        color: "#868686",
        fontSize: "12px",
    },
    renderHeaderInput: {
        "&>div>div": {
            color: '#868686',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 600,
            height: '38px',
            background: '#f2f2f2',
            borderRadius: '8px',
            border: '0.5px solid rgba(220, 220, 220, 0.2)',
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: "16px",
    },
    contentNome: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    textField: {
        height: "40px",
        width: "100%",
        "& input": {
          height: "23px",
          background: "#F2F2F2",
          border: "0.5px solid rgba(220, 220, 220, 0.2)",
          borderRadius: "8px",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 600,
          color: '#868686',
        },
        "& ::placeholder": {
          color: '#000'
        },
    },
    notchedOutlineTextField: {
        "& fieldset": {
            border: "none",
        },
    },
    notchedOutline: {
        border: 'none',
    },
    paperAlert: {
        maxWidth: "373px",
    },
    reactSelectCheckbox: {
        whiteSpace: 'nowrap',
        "&>div": {
            height: '40px',
            "&>div:first-child": {
                flexWrap: 'nowrap',
                height: '40px',
            },
        },
    }
}))(Procedimentos);

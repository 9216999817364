import Api from "../config/api";

export const createMensagemSistema = async (mensagemSistema) => {
  const response = await Api.post("", {
    query: `mutation CreateMensagemSistema($mensagemSistema : MensagemSistemaInput){
      createMensagemSistema(mensagemSistema: $mensagemSistema){
        id
      }
    }`, variables: { mensagemSistema }
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0])
  }
}

export const FindAllCampoMensagemSistemaParaMensagemSistema = async () => {
  const response = await Api.post("", {
    query: `query{
      findAllCampoMensagemSistemaParaMensagemSistema {
        campo
        descricao
        id
      }
    }
    `
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0])
  } else {
    return response.data.data.findAllCampoMensagemSistemaParaMensagemSistema
  }
}

export const FindMensagemSistemaByProfissionalSaudeId = async (profissionalSaudeId) => {
  try {
    const response = await Api.post("", {
      query: `query FindMensagemSistemaByProfissionalSaudeId($profissionalSaudeId: Long) {
  findMensagemSistemaByProfissionalSaudeId(profissionalSaudeId: $profissionalSaudeId) {
    profissionaisSaude {
      id
    }
    ativo
    id
    mensagem
    nome
    tipoPadrao
    unidade {
      id
    }
  }
}`, variables: { profissionalSaudeId }
    })
    if (response.data.errors) {
      throw new Error(response.data.errors[0])
    } else {
      return response?.data?.data?.findMensagemSistemaByProfissionalSaudeId
    }
  } catch (error) {
    throw new Error(response.data.errors.mensagem)
  }
}

export const UpdateMensagemSistema = async (mensagem) => {
  const response = await Api.post("", {
    query: `mutation UpdateMensagemSistema($mensagemSistema: MensagemSistemaInput) {
      updateMensagemSistema(mensagemSistema: $mensagemSistema) {
        id
        mensagem
        nome
        ativo
      }
    }`, variables: {
      mensagemSistema: {
        ...mensagem,
        id: mensagem.id,
        nome: mensagem.nome,
        mensagem: mensagem.mensagem,

      }
    }
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0])
  } else {
    return response
  }
}

export const FindMensagemSistemaById = async (id) => {
  const response = await Api.post("", {
    query: `query FindMensagemSistemaById($mensagemSistemaId: UUID) {
      findMensagemSistemaById(mensagemSistemaId: $mensagemSistemaId) {
        ativo
        id
        mensagem
        nome
        unidade {
          id
        }
        profissionaisSaude {
        id
        }
        tipoPadrao
        ativo
        tipoMensagemSistema
      }
    }`, variables: { mensagemSistemaId: id }
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0])
  } else {
    return response.data.data.findMensagemSistemaById
  }
}

export const findAllDadosMensagemSelecionada = async (findByIdAgendamentoId) => {
  const response = await Api.post("", {
    query: `query FindByIdAgendamento($findByIdAgendamentoId: Long) {
      findByIdAgendamento(id: $findByIdAgendamentoId) {
        convenio {
          id
          descricao
        }
        paciente {
        id
        }
        cpf
        dataFormatada
        dataNascimento
        horaInicio
        linkTelemedicina
        nome
        remoto
        chavePublica
        sujeitoAtencao {
          nome
          nomeSocial
          cns
          cpf
          rg
          idade
          id
          contato {
            telefonePrincipalDDI
            telefonePrincipal
          }
          dataNascimento
      }
        profissionalSaude {
          numeroConselho
          email
          id
          nome
          cpf
        }
        unidade {
          endereco {
            bairro
            cep
            complemento
            estado {
              nome
              uf
            }
            municipio {
              nome
              uf
            }
            nomeLogradouro
            numero
          }
          telefonePrincipal
          nomeFantasia
        }
          telefone
          telefoneDDI
      }
    }`, variables: { findByIdAgendamentoId }
  })
  if (response.data.errors) {
    throw new Error(response.data.errors[0])
  } else {
    return response.data.data.findByIdAgendamento
  }
}
import Api from "../config/api";
import { checkUserRole } from "../utils/checkUserRole";

export const findAgendamentosByIdSujeitoAtencao = (id) =>
	Api.post("", {
		query: `
			query ($id: UUID) {
				findAgendamentosByIdSujeitoAtencao(id: $id) {
					solicitante{
						nome
					}
					id
					data
					horaInicio
					horaFim
					remoto
					chavePublica
					situacao
					convenio {
						descricao
					}
					profissionalSaude {
						nome
					}
					procedimentos {
						procedimento {
							nome
						}
					}
					sala {
						nome
					}
					tipo {
						id
						descricao
					}
					situacao
				}
			}
		`,
		variables: { id },
	});

export const findAgendamentosByIdSujeitoAtencaoPage = async (id, pageableDTO) => {
	const response = await Api.post("", {
		query: `
			query ($id: UUID, $pageableDTO: PageableDTOInput) {
				findAgendamentosByIdSujeitoAtencaoPage(id: $id, pageableDTO: $pageableDTO) {
					last
					content {
						id
						data
						horaInicio
						horaFim
						remoto
						chavePublica
						situacao
						convenio {
							descricao
						}
						profissionalSaude {
							nome
						}
						procedimentos {
							procedimento {
								nome
							}
						}
						sala {
							nome
						}
						tipo {
							id
							descricao
						}
						situacao
						unidade {
							id
							nome
						}
					}
				}
			}
		`,
		variables: {
			id: id,
			pageableDTO,
		},
	});

	if (response.data.errors) {
		throw Error('Falha ao carregar os agendamentos do paciente selecionado.');
	}

	return response.data.data.findAgendamentosByIdSujeitoAtencaoPage;
};

export const findAllProcedimentoPedido = variables =>
	Api.post("", {
		query: `
			query ($search: String, $idSujeitoAtencao: UUID, $pageableDTO: PageableDTOInput, $idProfissionalSaude: Long) {
				findAllProcedimentoPedido(search: $search, idSujeitoAtencao: $idSujeitoAtencao, pageableDTO: $pageableDTO, idProfissionalSaude: $idProfissionalSaude) {
					last
					content {
						id
						observacao
						procedimentos {
							id
							agendado
							procedimento {
								id
								nome
							}
						}
						profissionalSaude {
							id
							nome
						}
						sujeitoAtencao {
							id
							nome
						}
					}
				}
			}
		`,
		variables: variables,
	});

export const findByAgendamentoProcedimentoPedido = (idAgendamento) =>
	Api.post("", {
		query: `
			query ($idAgendamento: Long) {
				findByAgendamentoProcedimentoPedido(idAgendamento: $idAgendamento) {
						id
						observacao
						procedimentosSolicitados {
							id
							agendado
							procedimentoPedido {
								id
							}
							procedimento {
								id
								nome
							}
						}
						profissionalSaude {
							id
							nome
						}
						sujeitoAtencao {
							id
							nome
						}
					}
				}
		`,
		variables: {
			idAgendamento,
		},
	});

export const findAllAgendamento = ({ searchDTO }) => {
	return Api.post("", {
		query: `
			query($searchDTO: SearchAgendamentoDTOInput) {
				findAllAgendamentoOutrosProfissionaisSaude(searchDTO: $searchDTO) {
					content {
						id
						nome
						data
						horaInicio
						horaFim
						sujeitoAtencao {
							id
							nome
						}
						profissionalSaude {
							id
							nome
						}
						situacao
					}
					last
				}
			}
		`,
		variables: {
			searchDTO,
		},
	});
};

export const findAllAgendamentoByDataHoraDecorrente = ({ searchDTO }) => {
	return Api.post("", {
		query: `
			query($searchDTO: SearchAgendamentoDTOInput) {
				findAllAgendamentoByDataHoraDecorrente(searchDTO: $searchDTO) {
					content {
						id
						nome
						data
						horaInicio
						horaFim
						sujeitoAtencao {
							id
							nome
						}
						profissionalSaude {
							id
							nome
						}
						situacao
					}
					last
					totalElements
				}
			}
		`,
		variables: {
			searchDTO,
		},
	});
};

export const updateAgendamentoSituacao = async (id, situacao) => {
	const response = await Api.post("", {
		query: `
				mutation($situacao: AgendamentoSituacao, $id: Long){
					updateAgendamentoSituacao(situacao: $situacao, id: $id) {
						id
						situacao
					}
				}
			`,
		variables: {
			situacao,
			id,
		},
	});

	const updatedAgendamentoId =
		response?.data?.data?.updateAgendamentoSituacao?.id;

	if (response.data.errors) {
		throw Error("Falha ao editar a situação do agendamento.");
	}
	return updatedAgendamentoId;
};

export const updateWhatsappMensagemManual = async (agendamentoId) => {
	const response = await Api.post("", {
		query: `
			mutation($agendamento: AgendamentoInput) {
				updateWhatsappMensagemManual(agendamento: $agendamento) {
					whatsappMensagemQuantidade
				}
			}
		`,
		variables: {
			agendamento: {
				id: agendamentoId,
			},
		},
	});

	const {
		whatsappMensagemQuantidade,
	} = response?.data?.data?.updateWhatsappMensagemManual;

	if (!whatsappMensagemQuantidade) {
		throw Error(
			"Falha ao incrementar a quantidade de mensagens de confirmação enviadas."
		);
	}

	return whatsappMensagemQuantidade;
};
export const findAllPeriodoAtendimentoModificado = async ({
	horaFim,
	dataFim,
	profissionalSaude,
	dataInicio,
	acao,
	horaInicio,
	pageableDTO,
}) => {
	const response = await Api.post("", {
		query: `
	  query (
	  $horaFim: LocalTime, 
	  $dataFim:LocalDate,
	  $profissionalSaude: Long,
	  $dataInicio: LocalDate,
	  $acao:AcaoPeriodoModificado,
	  $horaInicio:  LocalTime,
	  $pageableDTO: PageableDTOInput) {
	  findAllPeriodoAtendimentoModificado(
	  horaFim: $horaFim, 
	  dataFim:$dataFim,
	  profissionalSaude: $profissionalSaude,
	  dataInicio: $dataInicio,
	  acao: $acao,
	  horaInicio:  $horaInicio,
	  pageableDTO: $pageableDTO) {
		  last
		  content {
			acao
			data
			horaFim
			horaInicio
			id
			profissionalSaude{
			id
			}
			unidade{
			id
			}
		  }
		}
	  }`,
		variables: {
			horaFim,
			dataFim,
			profissionalSaude,
			dataInicio,
			acao,
			horaInicio,
			pageableDTO
		},
	});

	if (response.data?.data?.findAllPeriodoAtendimentoModificado) {
		return response.data.data.findAllPeriodoAtendimentoModificado;
	} else {
		throw new Error(response.data.errors[0]);
	}
};
export const findAgendamentosByIdSujeitoAtencaoFilters = async (variables) => {
	const response = await Api.post("", {
		query: `
		query ($profissinalId: Long, $dataFim: LocalDate, $sujeitoAtencaoId: UUID, $dataInicio: LocalDate, $pageableDTO: PageableDTOInput, $status: [AgendamentoSituacao]) {
			findAgendamentosByIdSujeitoAtencaoFilters(profissinalId: $profissinalId, dataFim: $dataFim, sujeitoAtencaoId: $sujeitoAtencaoId, dataInicio: $dataInicio, pageableDTO: $pageableDTO, status: $status) {
			  id
			  data
			  horaInicio
			  horaFim
			  situacao
			  profissionalSaude {
				nome
			  }
			  procedimentos {
				procedimento {
				  nome
				}
			  }
			  sala {
				nome
			  }
			  tipo {
				id
				descricao
			  }
			}
		  }
		`,
		variables: variables
	});

	const { data } = response;

	if (data.errors) {
		throw Error('Erro ao carregar os agendamentos.');
	}
	return data.data.findAgendamentosByIdSujeitoAtencaoFilters;
};


export const verificarAgendamentoRetorno = async (variables) => {
	const role = await checkUserRole('ROLE_PROFISSIONAL_SAUDE_READ');
	if (!role) return
	const response = await Api.post("", {
		query: `
		query ($sujeitoAtencaoId: UUID, $profissionalSaudeId: Long, $dataAgendamento: LocalDate) {
			verificarAgendamentoRetorno(sujeitoAtencaoId: $sujeitoAtencaoId, profissionalSaudeId: $profissionalSaudeId, dataAgendamento: $dataAgendamento) {
				data
			}
		}
		`,
		variables: variables
	});
	if (response.data.errors) {
		throw Error('Erro ao verificar agendamento de retorno.');
	}
	return response.data.data.verificarAgendamentoRetorno;
}

export const findAllAgendamentosListaDeEspera = async (variables) => {
	const response = await Api.post("", {
		query: `
		query ($profissionalSaudeId: Long, $dataInicial: LocalDate, $dataFinal: LocalDate, $pageableDTO: PageableDTOInput, $search: String, $especialidadeMedica: EspecialidadeMedicaInput) {
			findAllAgendamentosListaDeEspera(profissionalSaudeId: $profissionalSaudeId, dataInicial: $dataInicial, dataFinal: $dataFinal, pageableDTO: $pageableDTO, search: $search, especialidadeMedica: $especialidadeMedica) {
				totalElements
				content {
					id
					data
					nome
					horaInicio
					chavePublica
					remoto
					linkTelemedicina
					whatsappMensagemQuantidade
					mensagemWhatsappEnviada
					dataCriacao
					sujeitoAtencao {
						id
						nome
						contato{
							telefonePrincipal
							telefonePrincipalDDI
						}
					}
					tipo {
						id
						descricao
					}
					situacao
				}
				last
			}
		}
		`,
		variables: variables
	});
	if (response.data.errors) {
		throw Error('Erro ao carregar os agendamentos da lista de espera.');
	}
	return response.data.data.findAllAgendamentosListaDeEspera;
}
export const findAllConvenios = async (defaultSearchDTO, searchDTO) => {
	const response = await Api.post("", {
		query: `
			query ($searchDTO: SearchDTOInput) {
					findAllConvenio(searchDTO: $searchDTO) {
					last
					totalElements
					numberOfElements
					content {
							id
							descricao
							convenioAgendamentoTipo {
							agendamentoTipo {
									id
							}
							valor
							}
							convenioProcedimentos {
							procedimento {
									id
									nome
							}
							valor
							}
					}
					}
			}
							`,
		variables: {
			searchDTO: {
				...defaultSearchDTO,
				...searchDTO
			}
		}
	});
	if (response.data.errors) {
		throw Error('Erro ao carregar os convênios.');
	}
	return response.data.data.findAllConvenio;
};

export const validaProcedimentosSolicitados = async (variables) => {
	const response = await Api.post("", {
		query: `
			mutation($procedimentosSolicitados: [ProcedimentoSolicitadoInput]){
				validaProcedimentosSolicitados(procedimentosSolicitados : $procedimentosSolicitados)
			}
		`,
		variables,
	});
	const { validaProcedimentosSolicitados } = response?.data?.data;
	if (!validaProcedimentosSolicitados) {
		throw Error('Falha ao validar o procedimento pedido.');
	}
	return validaProcedimentosSolicitados;
}

export const modificarHorarioAtendimento = async (variables) => {
	const response = await Api.post("", {
		query: `
			mutation($profissionalSaudeId: Long, $periodos: [PeriodoAtendimentoModificadoInput]) {
				modificarHorarioAtendimento(profissionalSaude: $profissionalSaudeId, periodos: $periodos)
			}
		`,
		variables,
	});

	if (response.data.errors) {
		throw Error('Falha ao modificar o horário de atendimento.');
	}
	return response.data.data.modificarHorarioAtendimento;
}
export const modificarHorarioAtendimentoCascada = async (variables) => {
	const response = await Api.post("", {
		query: `
			mutation($profissionaisSaude: [Long], $periodos: [PeriodoAtendimentoModificadoInput]) {
				modificarHorarioAtendimentoCascada(profissionaisSaude: $profissionaisSaude, periodos: $periodos)
			}
		`,
		variables,
	});

	if (response.data.errors) {
		throw Error('Falha ao modificar o horário de atendimento.');
	}
	return response.data.data.modificarHorarioAtendimentoCascada;
}

export const contemAgendamento = async (variables) => {
	const response = await Api.post('', {
		query: `
			query($periodos: [PeriodoAtendimentoModificadoInput]) {
				contemAgendamento(periodos: $periodos)
			}
		`,
		
		variables: {
			periodos: variables
		},  
		
		
	});

	if (response.data.errors) {
		throw Error('Erro ao verificar a existência de agendamentos no período da ação.');
	}
	 if(response){
		console.log(response)
	}

	return response.data.data.contemAgendamento;
};

export const findAllProcedimentoPedidoSujeitoAtencao = async (variables) => {
	const response = await Api.post('', {
		query: `
			query FindAllProcedimentoPedido($idSujeitoAtencao: UUID, $pageableDTO: PageableDTOInput, $agendado: Boolean, $agendamentoid: Long) {
			findAllProcedimentoPedido(idSujeitoAtencao: $idSujeitoAtencao, pageableDTO: $pageableDTO, agendado: $agendado, agendamentoid: $agendamentoid) {
				last
				content {
					id
					observacao
					profissionalSaude {
						id
						nome
					}
					sujeitoAtencao {
						id
						nome
					}
					procedimentosSolicitadosFaturadosNaoAgendados {
						id
						agendado
						dataHoraLancamento
						procedimento {
							id
							nome
						}
						procedimentoPedido {
							id
							procedimentosSolicitados {
								id
								procedimento {
									id
								}
							}
						}
					}
				}
			}
		}
		`,
		variables: variables,
	});

	if (response.data.errors) {
		throw Error('Erro carregar os procedimentos solicitados.');
	}

	return response.data.data.findAllProcedimentoPedido;
};

export const findAllEspecialidadeMedica = async (variables) => {
	const response = await Api.post('', {
		query: `
			query ($pageableDTO: PageableDTOInput, $searchDTO: SearchDTOInput) {
				findAllEspecialidadeMedica(pageableDTO: $pageableDTO, searchDTO: $searchDTO) {
				  last
				  content {
						id
						especialidade
				  }
				}
			}
		`, variables: variables
	});

	if (response.data.errors) {
		throw Error('Erro ao carregar as especialidades médicas.');
	}
	return response.data.data.findAllEspecialidadeMedica;
}

export const findAllSujeitoAtencao = async (variables) => {
	const response = await Api.post('', {
		query: `
			query ($searchDTO: SearchSujeitoAtencaoDTOInput){
				findAllSujeitoAtencao(searchDTO: $searchDTO) {
					last
					content {
						id
						nome
						contato {
							telefonePrincipal
							telefonePrincipalDDI
						}
					}
				}
			}`,
		variables: variables
	})

	if(response.data.errors) {
		throw Error(response.data.errors[0].message)
	}
	return response.data.data.findAllSujeitoAtencao
}

export const findAllProfissionalSaudeComAgenda = async (variables) => {
  const response = await Api.post('', {
    query: `
      query($unidadeId: Long, $search: String, $pageableDTO: PageableDTOInput, $ativo: Boolean) {
					findProfissionalSaudeByUnidadeComAgendaPage(unidadeId: $unidadeId, search: $search, pageableDTO: $pageableDTO, ativo: $ativo) {
						last
						content {
							id
							nome
						}
					}
				}`,
    variables: variables
  })

  if(response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.findProfissionalSaudeByUnidadeComAgendaPage
}

export const findAllAgendamentoTipoPage = async (variables) => {
  const response = await Api.post('', {
    query: `
      query ($pageableDTO: PageableDTOInput, $ativo: Boolean, $search: String) {
        findAllAgendamentoTipoPage(ativo: $ativo,search: $search,pageableDTO: $pageableDTO) {
          last
          content {
            id
            descricao
          }
        }
      }`,
    variables: variables
  })

  if(response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.findAllAgendamentoTipoPage
}

export const createAgendamentoFilaEspera = async (variables) => {
	const response = await Api.post('', {
		query: `
			mutation ($agendamento: AgendamentoInput) {
				createAgendamentoFilaEspera(agendamento: $agendamento) {
					id
				}
			}`,
		variables: variables
	});

	if(response.data.errors) {
    throw Error(response.data.errors[0].message)
  }
  return response.data.data.createAgendamentoFilaEspera
}

export const findAllAgendamentoAuditListaEspera = async ({ pageableDTO }) => {
	const response = await Api.post('', {
		query: `
			query ($pageableDTO: PageableDTOInput) {
			findAllAgendamentoAuditListaEspera(pageableDTO: $pageableDTO) {
					last
					content {
						agendamentoRecorrenciaIdModified
						data
						revtype
						revisao {
							id
							usuario {
								id
								nome
								fotoPerfil
							}
							paciente {
								id
								nome
								fotoPerfil
							}
							dataHoraModificacao
						}
						convenio {
							descricao
						}
						convenioIdModified
						convenioNumeroCarteiraModified
						convenioValidadeCarteiraModified
						documentoModified
						documento
						dataChegadaSujeitoAtencaoModified
						dataChegadaSujeitoAtencao
						dataModified
						dataNascimentoModified
						dataNascimento
						horaFimModified
						horaFim
						horaInicioModified
						horaInicio
						listaDeEsperaModified
						listaDeEspera
						nomeModified
						nome
						numeroCarteira
						numeroGuiaTissExternaModified
						numeroGuiaTissExterna
						observacaoModified
						observacao
						pacienteIdModified
						profissionalSaudeIdModified
						profissionalSaude {
							nome
						}
						remotoModified
						remoto
						sala {
							nome
						}
						salaIdModified
						situacaoModified
						situacao
						solicitante {
							nome
						}
						solicitanteIdModified
						telefoneModified
						telefoneDDIModified
						telefone
						telefoneDDI
						tipoEntidade {
							descricao
						}
						tipoModified
						validadeCarteira
						motivoCancelamento
						motivoCancelamentoModified
						urgenteModified
						urgente
					}
				}
			}`,
		variables: { pageableDTO }
	});

	if (response.data.errors) {
		throw Error(response.data.errors[0].message)
	}
	return response.data.data.findAllAgendamentoAuditListaEspera
}
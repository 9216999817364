import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

import Menu from '../template/Menu/Menu'
import Pages from './Pages'
import ChatPopups from './Chat/ChatPopups'
import NotificationModal from '../components/Modal/NotificationModal'
import { checkUserRole } from '../utils/checkUserRole'

const App = observer(({ usuarioStore, socketStore, chatStore, history }) => {
    const { isAuthenticated } = usuarioStore
    const [userCanViewChat, setUserCanViewChat] = useState(false)
    
    const checkSocketConnection = async () => {
      const { isApiInitialized, isConnectionOpen } = socketStore
      const userCanViewChat = await checkUserRole('ROLE_CHAT_READ')
      setUserCanViewChat(userCanViewChat);

      if (!isAuthenticated || !userCanViewChat) {
        return
      }

      if (!isApiInitialized || !isConnectionOpen) {
        await chatStore.chatLocalConnection();
      }
    }

    useEffect(() => {
      if (isAuthenticated) {
        checkSocketConnection()
      }
    }, [isAuthenticated])

    return (
      <div className={`main`}>
        {isAuthenticated && <Menu />}
        <Pages isAuthenticated={isAuthenticated} />
        <ToastContainer />
        {isAuthenticated && userCanViewChat && <ChatPopups />}
        {isAuthenticated && <NotificationModal history={history} />}
      </div>
    )
  }
)

const stores = ['usuarioStore', 'chatStore', 'loginStore', 'socketStore', 'chatStore']
export default inject(...stores)(App)
